import React from 'react';
import './subjects.scss';
import withRouter from '../../../nix_components/components/withRouter';
import Grid from '../../../nix_components/ui_components/layouts/Grid/Grid';
import Nx_Button from '../../../nix_components/ui_components/controls/nx_button';
import SubjectSelect from './components/SubjectSelect';
import SubjectTag from './components/SubjectTag';
import SubjectApproveButton from './components/SubjectApproveButton';
import SubjectInput from './components/SubjectInput';
import DeleteItemModal from '../../../components/modals/DeleteItemModal';
import { usersTypes } from '../../../nix_components/data/DataReferences';
import moment from 'moment';

class Subjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            subject_type: null,
            deleteItem: null,
            patient_id: null,
            is_new: false,
            subject: null,
            not_editable: false,
            initializing: true,
            willUnmount: false,
            subject_details: {},
            original_subject_details: {},
            social_worker: {},
            psychologist: {},
            special_educator_and_rehabilitator: {},
            doctor: {},
            regions: {},
            patients: {},
            view_patients_last_subject: {},
            read_only: false,
            is_printed: false
        };
    }

    handleInitialization = async params => {
        const patient_id = params.patient_id;
        const id = params.id;
        const subject_type = params.type;
        const patients = await global.UF.dataProvider.get_v2(`patients`, {
            id: patient_id
        });

        if (!patients.length) {
            global.UF.setAlertVisibility(true, 'Не постои пациент со тој ИД број!', 'warning');
            return;
        }

        const view_patients_last_subjects = await global.UF.dataProvider.get_v2(`view_patients_last_subject`, {
            patient_id: patient_id
        });

        const view_patients_last_subject = view_patients_last_subjects.length ? view_patients_last_subjects[0] : {};
        const patient = patients[0];
        patient.date_of_birth = patient.date_of_birth
            ? moment(patient.date_of_birth).format('YYYY-MM-DD')
            : patient.date_of_birth;
        const region_id = patient.region_id;

        const regions = await global.UF.dataProvider.get_v2(`regions`, {
            id: region_id
        });

        if (!regions.length) {
            global.UF.setAlertVisibility(true, 'Не постоји регион са овим ИД', 'warning');
            return;
        }

        const region = regions[0];

        if (id) {
            const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
                id: id
            });
            if (!subjects.length) {
                global.UF.setAlertVisibility(true, 'Не постои предмет со тој ИД број!', 'warning');
                return;
            }

            let subject_details = await global.UF.dataProvider.get_v2(`subject_details`, { subject_id: id });
            const parsedObj = {};
            subject_details.forEach(sd => {
                parsedObj[sd['subject_key']] = sd['value_str'];

                if (sd[['value_multiple_ref']]) {
                    parsedObj[sd['subject_key']] = sd['value_multiple_ref'];
                }

                if (sd[['value_single_ref']]) {
                    parsedObj[sd['subject_key']] = sd['value_single_ref'];
                }

                if (sd['value_tag']) {
                    parsedObj[sd['subject_key']] = sd['value_tag'];
                }

                if (sd['value_int']) {
                    parsedObj[sd['subject_key']] = sd['value_int'];
                }
            });

            this.setState({
                subject_details: { ...parsedObj },
                original_subject_details: { ...parsedObj },
                id: id,
                subject_type: subject_type,
                is_new: false,
                patient_id: patient_id,
                subject: subjects[0],
                not_editable: subjects[0].status !== 'in_progress' && subjects[0].status !== 'approved',
                patients: patient,
                view_patients_last_subject: view_patients_last_subject,
                regions: region
            });
        } else {
            this.props.router.navigate(`/patients/${patient_id}`);
        }
    };

    fetchAllNecesseryRefs = async () => {
        let ref_keys = await global.UF.dataProvider.get_v2(`subject_keys`, {
            type: ['ss', 'tag', 'ms']
        });

        if (!ref_keys.length) {
            global.UF.setAlertVisibility(true, 'There are no references to fetch!', 'info');
            this.setState({ initializing: false });
        }

        const uniqueTypes = new Set();
        const unique_refs = ref_keys.filter(obj => !uniqueTypes.has(obj.ref) && uniqueTypes.add(obj.ref));

        unique_refs.forEach((ref_key, index) => {
            const reference_type_and_reference = ref_key.ref;
            let reference = reference_type_and_reference.split('.')[1];
            let filters = {};
            if (reference.includes(':')) {
                let split = reference.split(':');
                filters = split[1];
                reference = split[0];
                filters = filters.split('=');
                const temp_filters = {};
                if (reference === 'users') {
                    filters[1] = filters[1].split(',');
                }
                temp_filters[filters[0]] = filters[1];
                filters = temp_filters;
            }

            if (reference === 'users') {
                filters = {
                    custom_filter: ` FIND_IN_SET('"${this.state.patients.region_id}"', REPLACE(REPLACE(REPLACE(region_id, '[', ''), ']', ''), ' ', '')) > 0 AND user_type_id IN (2, 3, 4)`
                };
            }

            global.UF.dataProvider.referenceProvider.get(
                reference,
                data => {
                    if (index + 1 === unique_refs.length) {
                        this.setState({ initializing: false });
                    }
                },
                true,
                filters
            );
        });
    };

    handleWholeInitialization = async router => {
        const { params } = router;
        await this.handleInitialization(params);
        await this.fetchSpecialistAndDoctors();
        await this.fetchAllNecesseryRefs();
    };

    beforeUnloadHandler = event => {
        const isChanged = Object.keys(this.returningOnlyChangedFields()).length > 0;
        if (!isChanged) return;

        event.preventDefault();
        event.returnValue = '';
        return '';
    };

    popstateHandler = () => {
        const isChanged = Object.keys(this.returningOnlyChangedFields()).length > 0;
        if (isChanged) {
            const userConfirmed = window.confirm(
                'Документот не е сочуван. Дали сте сигурни дека сакате да излезете од документот?'
            );

            this.history.block(() => {
                if (!userConfirmed) {
                    return '';
                }
            });
        }
    };

    componentDidMount = async () => {
        // window.addEventListener("beforeunload", this.beforeUnloadHandler, {
        //   capture: true,
        // });
        // window.addEventListener("popstate", this.popstateHandler, {
        //   capture: true,
        // });

        await this.handleWholeInitialization(this.props.router);
    };

    componentWillUnmount = () => {
        // window.removeEventListener("beforeunload", this.beforeUnloadHandler);
        // window.removeEventListener("popstate", this.popstateHandler);
    };

    shouldComponentUpdate = async nextProps => {
        const params = this.props.router.params;
        const nextParams = nextProps.router.params;

        if (params.hasOwnProperty('id') && !nextParams.hasOwnProperty('id')) {
            this.props.router.navigate(`/patients/${params.patient_id}`);
            return true;
        }

        if (JSON.stringify(params) !== JSON.stringify(nextParams)) {
            await this.handleWholeInitialization(nextProps.router);
        }

        return true;
    };

    handleMultipleReferences = async (subject_details_arr, callback) => {
        if (!subject_details_arr.length) {
            callback();
            return;
        }

        const multiple_refs = subject_details_arr.filter(
            item => Array.isArray(item.value) && typeof item.value !== 'string'
        );

        if (!multiple_refs.length) {
            callback();
            return;
        }

        const ref_keys = multiple_refs.map(ref => ref.key);

        const subject_keys = await global.UF.dataProvider.get_v2(`subject_keys`, {
            key: ref_keys
        });

        subject_keys.forEach((subject_key, index) => {
            const split = subject_key.ref.split('.');
            const reference_with_filter = split[1];
            let reference = '';
            if (reference_with_filter.includes(':')) {
                reference = reference_with_filter.split(':')[0];
            } else {
                reference = reference_with_filter;
            }

            const current_ref = multiple_refs.find(ref => ref.key === subject_key.key);
            if (!current_ref) {
                console.error(`Current ref not found!`);
                return;
            }

            const value = current_ref.value;
            current_ref.valueMultiRef = [];

            global.UF.dataProvider.referenceProvider.get(
                reference,
                data => {
                    current_ref.valueMultiRef = data
                        .filter(item => value.includes(item.id))
                        .map(item => item.label)
                        .join(',');
                    if (index + 1 === subject_keys.length) {
                        if (callback) {
                            callback();
                        }
                    }
                },
                false
            );
        });
    };

    handleSingleReferences = async (subject_details_arr, callback) => {
        if (!subject_details_arr.length) {
            callback();
        }

        const keys = subject_details_arr.map(item => item.key);

        const single_refs_details = await global.UF.dataProvider.get_v2(`subject_keys`, {
            key: keys,
            type: 'ss'
        });

        if (!single_refs_details.length) {
            callback();
        }

        const single_refs_keys = single_refs_details.map(ref_key => ref_key.key);

        const single_refs = subject_details_arr.filter(item => {
            if (single_refs_keys.includes(item.key)) {
                item.reference = single_refs_details.find(key_detail => key_detail.key === item.key).ref;
                return true;
            }
            return false;
        });

        if (!single_refs.length) {
            callback();
        }

        single_refs.forEach((ref, index) => {
            let reference = '';
            reference = ref.reference.split('.')[1];
            if (reference.includes(':')) {
                const split = reference.split(':');
                reference = split[0];
            }

            global.UF.dataProvider.referenceProvider.get(
                reference,
                data => {
                    const valueSingleRef = data.find(item => item.id == ref.value);
                    ref.valueSingleRef = valueSingleRef.label;

                    if (single_refs.length == index + 1) {
                        if (callback) {
                            callback();
                        }
                    }
                },
                false
            );
        });
    };

    handleTags = async (subject_details, callback) => {
        if (!subject_details.length) {
            callback();
        }

        const keys = subject_details.map(sd => sd.key);

        const tags_details_keys = await global.UF.dataProvider.get_v2(`subject_keys`, {
            key: keys,
            type: 'tag'
        });

        if (!tags_details_keys?.length) {
            if (callback) {
                callback();
            }
        }

        const tags_keys = tags_details_keys.map(ref_key => ref_key.key);

        const tags_keys_details = subject_details.filter(item => {
            if (tags_keys.includes(item.key)) {
                return true;
            }
            return false;
        });

        tags_keys_details.forEach(detail => {
            const regex = /\[\[.*?\]\]/g;
            const matches = detail.value?.match(regex);
            detail.valueTag = '';
            let tempValue = detail.value;

            if (matches && matches.length) {
                matches.forEach(match => {
                    const parsed_match = JSON.parse(match);
                    tempValue = tempValue.replace(match, parsed_match[0][0].value);
                });
                detail.valueTag = tempValue;
            }
        });
        if (callback) {
            callback();
        }
    };

    returningOnlyChangedFields = () => {
        let temp_subject_details = {};

        Object.keys(this.state.subject_details).forEach(key => {
            if (this.state.original_subject_details[key] == undefined) {
                temp_subject_details[key] = this.state.subject_details[key];
            }

            if (this.state.original_subject_details[key] != this.state.subject_details[key]) {
                temp_subject_details[key] = this.state.subject_details[key];
            }
        });

        return temp_subject_details;
    };

    handleSavingSubject = async (redirect = true) => {
        const changed_subject_details = this.returningOnlyChangedFields();

        const db_fields = await global.UF.dataProvider.get_v2(`subject_keys`, {
            type: 'db'
        });

        const db_obj = {};
        db_fields.forEach(db_field => {
            const split = db_field.key.split('.');
            const table = split[0];
            const field = split[1];
            if (
                [
                    'psychologist.specialist_type',
                    'social_worker.specialist_type',
                    'special_educator_and_rehabilitator.specialist_type'
                ].includes(db_field.key)
            ) {
                db_obj[db_field.key] = global.UF.tr(this.state[table][field]);
            } else {
                db_obj[db_field.key] = this.state[table][field];
            }
        });

        const full_subject_details = Object.assign(changed_subject_details, db_obj);

        const keys = Object.keys(changed_subject_details);
        if (!keys.length) {
            if (redirect) {
                global.UF.setAlertVisibility(true, 'Ништо не е променето!', 'warning');
            }
            return;
        }

        const subject_details_arr = Object.entries(full_subject_details).map(([key, value]) => {
            return { key, value };
        });

        await this.handleSingleReferences(subject_details_arr, async () => {
            await this.handleMultipleReferences(subject_details_arr, async () => {
                await this.handleTags(subject_details_arr, async () => {
                    try {
                        await global.UF.makeRequest_v2(
                            `POST`,
                            `/api/subjects/save`,
                            {
                                subject_details: subject_details_arr,
                                subject_id: this.state.id
                            },
                            true
                        );
                    } catch (err) {
                        global.UF.handleError(err);
                        return;
                    }
                });
            });
        });

        return;
    };

    fetchSpecialistAndDoctors = async () => {
        const { id } = this.props.router.params;
        const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
            id: id
        });

        if (!subjects.length) {
            global.UF.setAlertVisibility(true, 'Нема предмета са тим ИД', 'info');
            return;
        }

        const subject = subjects[0];

        const temp_fields = ['social_worker', 'psychologist', 'special_educator_and_rehabilitator', 'doctor'];

        for (const field of temp_fields) {
            const id = subject[`${field}_id`];
            if (id) {
                const users = await global.UF.dataProvider.get_v2(`users`, {
                    id: subject[`${field}_id`]
                });
                this.state[field] = users[0];
            }
        }

        this.forceUpdate();

        /** HERE WE SET THE READONLY IF ALL OF THEM SIGNED DOCUMENT */

        // let read_only = true;
        // for (const field of temp_fields) {
        //   if (!this.state[field] || Object.keys(this.state[field]).length == 0) {
        //     read_only = false;
        //     break;
        //   }
        // }

        // this.setState({ read_only });
    };

    handleUpdatePreviousSubjectNumberRevaluation = async () => {
        const keys = Object.keys(this.state.view_patients_last_subject);
        if (!keys.length) {
            return;
        }
        const subject_id = this.state.view_patients_last_subject.id;
        const delovoden_broj = this.state.view_patients_last_subject.delovoden_broj;

        const control_checkups = await global.UF.dataProvider.get_v2(`subjects`, {
            patient_id: this.state.patient_id,
            subject_type: 'control_check_up',
            status: ['re_evaluated', 'final']
        });

        const current_subject = await global.UF.dataProvider.get_v2(`subjects`, {
            id: this.state.id
        });

        const subject = current_subject[0];

        const subject_delovoden_broj = subject.delovoden_broj;

        const number_of_control_checkups = control_checkups.length + 1;

        const broj_na_realizirana_revaluacija = `${subject_delovoden_broj} на ${delovoden_broj}-${number_of_control_checkups}`;

        const subject_to_be_updated_response = await global.UF.dataProvider.get_v2(`subjects`, { id: subject_id });

        const subject_to_be_updated = subject_to_be_updated_response[0];

        subject_to_be_updated.broj_na_realizirana_revaluacija = broj_na_realizirana_revaluacija;

        subject_to_be_updated.Save(() => {});
    };

    handlePublishSubject = async () => {
        const validate_fields = [
            'social_worker_id',
            'psychologist_id',
            'special_educator_and_rehabilitator_id',
            'doctor_id'
        ];
        let valid = true;

        const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
            id: this.state.id
        });

        const subject = subjects[0];

        validate_fields.forEach(field => {
            valid = valid && subject[field];
        });

        const subject_details = await global.UF.dataProvider.get_v2(`subject_details`, { subject_id: this.state.id });

        if (!subject_details.length) {
            global.UF.setAlertVisibility(
                true,
                'Документот не смее да биде целосно празен, сочувајте ги вашите податоци!',
                'warning'
            );
            return;
        }

        const ordinal_number =
            subject.subject_type === 'first_check_up'
                ? subject.delovoden_broj
                : this.state.subject_details['1_0_patients_ordinal_number'];

        if (!ordinal_number || ordinal_number.replaceAll(' ', '').length == 0) {
            global.UF.setAlertVisibility(
                true,
                'Внесете број на идентификација, сочувајте ги вашите податоци!',
                'warning'
            );
            return;
        }

        valid = true;

        const date_of_reassessment_index = subject_details.findIndex(
            detail => detail.subject_key == '3_5_date_of_reassessment'
        );

        // if (
        //   !subject_details[date_of_reassessment_index] ||
        //   !subject_details[date_of_reassessment_index].value_str
        // ) {
        //   global.UF.setAlertVisibility(
        //     true,
        //     "Датумот за контролна проценка мора да биде пополнет, сочувајте ги вашите податоци!",
        //     "warning"
        //   );
        //   return;
        // }

        if (!valid) {
            global.UF.setAlertVisibility(
                true,
                'Сите стручни лица мора да потпишат, сочувајте ги вашите податоци!',
                'warning'
            );
            return;
        }

        await this.handleSavingSubject(false);

        if (this.state.subject_type === 'appeal') {
            const temp_subjects = await global.UF.dataProvider.get_v2(`subjects`, {
                patient_id: this.state.patient_id,
                subject_type: ['first_check_up', 'control_check_up'],
                status: 'appealed'
            });

            const last_created_subject = temp_subjects.reduce((next, current) => {
                const next_created_at = new Date(next.created_at);
                const current_created_at = new Date(current.created_at);

                return next_created_at > current_created_at ? next : current;
            }, temp_subjects[0]);

            last_created_subject.status = 're_evaluated';
            last_created_subject.Save(() => {});
        }

        // if (this.state.subject_type === "archived") {
        //   if (this.state.subject_details["1_2_number_and_date_of_subject"]) {
        //     subject.subject_type = "control_check_up";
        //   } else {
        //     subject.subject_type = "first_check_up";
        //   }
        // }

        subject.status = this.state.subject_type === 'archived' ? 'final' : 'issued';
        subject.date_of_publishing = new Date();
        await this.handlePrintingToPdf();
        await this.handleUpdatePreviousSubjectNumberRevaluation();

        subject.Save(async () => {
            const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
                id: this.state.id
            });

            const subject = subjects[0];

            this.setState({ subject, not_editable: true });
        });
    };

    handlePrintingToPdf = async () => {
        try {
            await global.UF.makeRequest_v2(`POST`, `/api/subjects/print`, {
                subject_id: this.state.id
            });
        } catch (err) {
            global.UF.handleError(err);
        }
    };

    handleDeleteSubject = () => {
        this.state.subject.Delete(() => {
            this.props.router.navigate(`/patients/${this.state.patient_id}`);
        });
    };

    renderDeleteModal = () => {
        return (
            <DeleteItemModal
                open={this.state.deleteItem !== null}
                onClose={() => this.setState({ deleteItem: null })}
                onDelete={() => this.handleDeleteSubject()}
            />
        );
    };

    handlePrintingDocument = async () => {
        global.UF.setMaskVisibility(true);

        // await this.handleSavingSubject(false);
        await this.handlePrintingToPdf();

        const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
            id: this.state.id
        });

        const subject = subjects[0];

        setTimeout(() => {
            this.setState({ subject: subject, is_printed: true }, () => {
                global.UF.setMaskVisibility(false);
            });
        }, 1500);
    };

    renderButtons = () => {
        if (this.state.subject.status == 'in_progress' || this.state.subject.status == 'approved') {
            return (
                <>
                    <Nx_Button
                        label="Избриши предмет"
                        variant="text"
                        size={'small'}
                        onClick={() => this.setState({ deleteItem: this.state.subject })}
                    />
                    <Nx_Button
                        label="Испринтај документ"
                        variant="text"
                        size={'small'}
                        onClick={this.handlePrintingDocument}
                    />
                    {usersTypes[this.props.user.user_type_id] == 'doctor' ? null : (
                        <Nx_Button
                            label="Издади документ"
                            variant="text"
                            size={'small'}
                            onClick={this.handlePublishSubject}
                        />
                    )}

                    <Nx_Button label="Сочувај" variant="text" size={'small'} onClick={this.handleSavingSubject} />
                </>
            );
        }
        return null;
    };

    renderIFrame = () => {
        const file_path = `${window.location.origin}/api/files/subjects/${
            this.state.subject.file_path
        }?qs=${new Date().getTime()}`;
        return (
            <>
                <div style={{ width: '100%', height: '100%' }}>
                    {this.state.is_printed ? (
                        <Nx_Button
                            label="Назад"
                            variant="text"
                            size={'small'}
                            onClick={() => this.setState({ is_printed: false })}
                        />
                    ) : null}
                    <iframe title="PDF Viewer" key={new Date().getTime()} width="100%" height="100%" src={file_path}>
                        Your browser does not support PDFs. Please download the PDF to view it.
                    </iframe>
                </div>
            </>
        );
    };

    render() {
        if (this.state.initializing) {
            return null;
        }
        if (this.state.not_editable || this.state.is_printed) {
            return this.renderIFrame();
        }

        return (
            <Grid style={{ gridTemplateRows: '50px auto', overflow: 'hidden' }}>
                {this.state.deleteItem && this.renderDeleteModal()}
                <Grid className="subject_buttons">{this.renderButtons()}</Grid>

                <div className={`subject_wrapper ${this.state.subject_type === 'archived' ? 'archived' : ''}`}>
                    <div class="c75 doc-content">
                        <div>
                            <p class="c2" style={{ textAlign: 'center' }}>
                                <span class="c34 c6 c113">
                                    Ј.З.У ЗДРАВСТВЕН ДОМ НА СКОПЈЕ Скопје- О.Е. СЛУЖБА ЗА ФУНКЦИОНАЛНА ПРОЦЕНКА НА ДЕЦА
                                    И МЛАДИ
                                </span>
                            </p>
                            <p class="c2 c3">
                                <span class="c0"></span>
                            </p>
                        </div>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p
                            class="c20"
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <span class="c0" style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                НАОД И МИСЛЕЊЕ
                            </span>
                        </p>
                        <p
                            class="c20"
                            style={{
                                textAlign: 'center',
                                fontSize: '22px',
                                fontWeight: 'bold'
                            }}
                        >
                            <span class="c0" style={{ fontSize: '22px', fontWeight: 'bold' }}>
                                за функционална проценка
                            </span>
                        </p>
                        <p class="c20">
                            <span>Стручно тело за функционална проценка</span>
                            <span>- </span>
                            <span class="c6" id="regions.description">
                                {this.state.regions.description}
                            </span>
                            <span class="c0">&nbsp;</span>
                        </p>
                        <p class="c20">
                            <span class="c6" id="regions.address">
                                {this.state.regions.address}
                            </span>
                            <span class="c0">&nbsp;</span>
                        </p>
                        <p class="c20">
                            <span>Mail: </span>
                            <span class="c6" id="regions.email">
                                {this.state.regions.email}
                            </span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                        </p>
                        <p class="c20">
                            <span>Тел: </span>
                            <span class="c6" id="regions.phone">
                                {this.state.regions.phone}
                            </span>
                            <span class="c0">&nbsp;</span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_3-0 start" start="1">
                            <li
                                class="c20 c50 li-bullet-0"
                                style={{
                                    textAlign: 'center',
                                    marginLeft: '0',
                                    paddingLeft: '0 '
                                }}
                            >
                                <span class="c0">ОСНОВНИ И ЛИЧНИ ПОДАТОЦИ</span>
                            </li>
                        </ol>
                        <a id="t.645da50f703e8fff4a7685225d2dd70081449ae2"></a>
                        <a id="t.0"></a>
                        <table class="c46">
                            <tbody>
                                <tr class="c110">
                                    <td class="c32 c99" colspan="3" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Информации релевантни за управување со проценката</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c53">
                                    <td class="c51" colspan="3" rowspan="1">
                                        <p class="c2">
                                            <span>Број на идентификација:</span>
                                            <span class="c6" id="subject.delovoden_broj">
                                                {this.state.subject.delovoden_broj}
                                            </span>
                                            <span class="c30 c6" id="1_0_patients_ordinal_number">
                                                <SubjectInput
                                                    type="text"
                                                    read_only={this.state.read_only}
                                                    className="sbj_stb sbj_key_1_0_patients_ordinal_number"
                                                    subject_details={this.state.subject_details}
                                                    field={'1_0_patients_ordinal_number'}
                                                />
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c93">
                                    <td class="c51" colspan="3" rowspan="1">
                                        <p class="c2">
                                            <span>Стручен тим:</span>
                                            <span class="c6" id="regions.name">
                                                {this.state.regions.name}
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c82">
                                    <td class="c76 c116" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span>Одговорно лице за проценката</span>
                                            <span>:</span>
                                            <span class="c0" id="1_0_responsible_person">
                                                <SubjectSelect
                                                    read_only={this.state.read_only}
                                                    reference={'remote.users:user_type_id=2,3,4'}
                                                    className="sbj_ss sbj_key_1_0_responsible_person"
                                                    subject_details={this.state.subject_details}
                                                    field={'1_0_responsible_person'}
                                                />
                                            </span>
                                        </p>
                                    </td>
                                    <td class="c76 c90" colspan="2" rowspan="1">
                                        <p
                                            class="c2"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px'
                                            }}
                                        >
                                            <span>Датум на проценка: </span>
                                            <span class="c30 c6" id="1_0_datum_na_procenka">
                                                <SubjectInput
                                                    type="date"
                                                    read_only={this.state.read_only}
                                                    className="sbj_date sbj_key_1_0_datum_na_procenka"
                                                    subject_details={this.state.subject_details}
                                                    field={'1_0_datum_na_procenka'}
                                                />
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c60">
                                    <td class="c99 c32" colspan="3" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Основни информации за детето/младинецот</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c51" colspan="3" rowspan="1">
                                        <p class="c2">
                                            <span>Име, татково име и презиме: </span>
                                            <span class="c6" id="patients.first_name">
                                                {this.state.patients.first_name}
                                            </span>
                                            <span>&nbsp;</span>
                                            <span class="c6" id="patients.fathers_name">
                                                {this.state.patients.fathers_name}
                                            </span>
                                            <span>&nbsp;</span>
                                            <span class="c6 c30" id="patients.last_name">
                                                {this.state.patients.last_name}
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c82">
                                    <td class="c61" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span>Дата и место на раѓање: &nbsp; </span>
                                            <span class="c6" id="patients.date_of_birth">
                                                {this.state.patients.date_of_birth
                                                    ? moment(this.state.patients.date_of_birth).format('DD-MM-YYYY')
                                                    : this.state.patients.date_of_birth}
                                            </span>
                                            <span>&nbsp;</span>
                                            <span class="c6">,</span>
                                            <span>&nbsp;</span>
                                            <span class="c30 c6" id="patients.birth_place">
                                                {this.state.patients.birth_place}
                                            </span>
                                        </p>
                                    </td>
                                    <td class="c39 c76" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span>ЕМБГ: </span>
                                            <span class="c30 c6" id="patients.id_number">
                                                {this.state.patients.id_number}
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c82">
                                    <td class="c61" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span>Адреса и место на живеење/Општина:</span>
                                            <span class="c6" id="patients.address">
                                                {this.state.patients.address}
                                            </span>
                                            <span>&nbsp;/ </span>
                                            <span class="c30 c6" id="patients.community">
                                                {this.state.patients.community}
                                            </span>
                                        </p>
                                    </td>
                                    <td class="c39 c76" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span>Број на телефон: &nbsp;</span>
                                            <span class="c30 c6" id="patients.contact_phone">
                                                {this.state.patients.contact_phone}
                                            </span>
                                        </p>
                                        <p class="c2">
                                            <span>Електронска пошта:</span>
                                            <span class="c30 c6" id="patients.contact_email">
                                                {this.state.patients.contact_email}
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c82">
                                    <td class="c61" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span>Мајчин јазик:</span>
                                            <span class="c30 c6" id="1_1_majcin_jezik">
                                                <SubjectInput
                                                    type="text"
                                                    read_only={this.state.read_only}
                                                    className="sbj_stb sbj_key_1_1_majcin_jezik"
                                                    subject_details={this.state.subject_details}
                                                    field={'1_1_majcin_jezik'}
                                                />
                                            </span>
                                        </p>
                                    </td>
                                    <td class="c39 c76" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span>Пол: &nbsp;</span>
                                            <span class="c30 c6" id="patients.gender">
                                                {this.state.patients.gender}
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <a id="t.6fb6ed9f4d686cc1535d53993e6201e4fdd94a8d"></a>
                        <a id="t.1"></a>
                        <table class="c46">
                            <tbody>
                                <tr class="c60">
                                    <td class="c99 c32" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Основни информации за претходните проценки</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c51" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span>Број и датум на претходните проценки: &nbsp;</span>
                                            <span class="c6 c69" id="view_patients_last_subject.number_and_date">
                                                {this.state.view_patients_last_subject.number_and_date}
                                            </span>
                                            <span>&nbsp;</span>
                                            <span class="c6">&nbsp;</span>
                                            <span id="1_2_number_and_date_of_subject">
                                                <SubjectInput
                                                    type="text"
                                                    read_only={this.state.read_only}
                                                    className="sbj_stb sbj_key_1_2_number_and_date_of_subject"
                                                    subject_details={this.state.subject_details}
                                                    field={'1_2_number_and_date_of_subject'}
                                                />
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c51" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span>
                                                Поставени цели со претходните проценки (описно од исказ на родител):{' '}
                                            </span>
                                            <span class="c30 c6" id="1_2_postaveni_celi_so_prethodnite_pocenki">
                                                <SubjectInput
                                                    multiline={true}
                                                    type="text"
                                                    read_only={this.state.read_only}
                                                    className="sbj_mtb sbj_key_1_2_postaveni_celi_so_prethodnite_pocenki"
                                                    subject_details={this.state.subject_details}
                                                    field={'1_2_postaveni_celi_so_prethodnite_pocenki'}
                                                />
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c92">
                                    <td class="c51" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span>Степен на реализација на поставените цели: </span>
                                            <span class="c30 c6" id="1_2_stepen_na_realizacija_na_postavenite_celi">
                                                <SubjectInput
                                                    multiline={true}
                                                    type="text"
                                                    read_only={this.state.read_only}
                                                    className="sbj_mtb sbj_key_1_2_stepen_na_realizacija_na_postavenite_celi"
                                                    subject_details={this.state.subject_details}
                                                    field={'1_2_stepen_na_realizacija_na_postavenite_celi'}
                                                />
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c102 c76" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Целосно реализирани:</span>
                                        </p>
                                    </td>
                                    <td class="c52" colspan="1" rowspan="1" id="1_2_celosno_realizirani">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            read_only={this.state.read_only}
                                            className="sbj_mtb sbj_key_1_2_celosno_realizirani"
                                            subject_details={this.state.subject_details}
                                            field={'1_2_celosno_realizirani'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c102 c76" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Делумно реализирани (причини):</span>
                                        </p>
                                    </td>
                                    <td class="c52" colspan="1" rowspan="1" id="1_2_delumno_realizirani">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            read_only={this.state.read_only}
                                            className="sbj_mtb sbj_key_1_2_delumno_realizirani"
                                            subject_details={this.state.subject_details}
                                            field={'1_2_delumno_realizirani'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c76 c102" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Нереализирани цели (причини):</span>
                                        </p>
                                    </td>
                                    <td class="c52" colspan="1" rowspan="1" id="1_2_nerealizirani_celi">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            read_only={this.state.read_only}
                                            className="sbj_mtb sbj_key_1_2_nerealizirani_celi"
                                            subject_details={this.state.subject_details}
                                            field={'1_2_nerealizirani_celi'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c102 c76" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Реализација на препорачаните услуги (описно):</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c52"
                                        colspan="1"
                                        rowspan="1"
                                        id="1_2_realizacija_na_preporacanite_uslugi"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            read_only={this.state.read_only}
                                            className="sbj_mtb sbj_key_1_2_realizacija_na_preporacanite_uslugi"
                                            subject_details={this.state.subject_details}
                                            field={'1_2_realizacija_na_preporacanite_uslugi'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c102 c76" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Задоволство на родителот/детето од добиените услуги:</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c52"
                                        colspan="1"
                                        rowspan="1"
                                        id="1_2_zadovolstvo_na_roditelot_deteto_od_dobienite_uslugi"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            read_only={this.state.read_only}
                                            className="sbj_mtb sbj_key_1_2_zadovolstvo_na_roditelot_deteto_od_dobienite_uslugi"
                                            subject_details={this.state.subject_details}
                                            field={'1_2_zadovolstvo_na_roditelot_deteto_od_dobienite_uslugi'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c67">
                                    <td class="c102 c76" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">
                                                Потреба од дополнителни услуги (во периодот меѓу проценките, според
                                                родителот)
                                            </span>
                                        </p>
                                    </td>
                                    <td class="c52" colspan="1" rowspan="1" id="1_2_potreba_od_dopolnitelni_uslugi">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_1_2_potreba_od_dopolnitelni_uslugi"
                                            subject_details={this.state.subject_details}
                                            field={'1_2_potreba_od_dopolnitelni_uslugi'}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_3-0" start="2">
                            <li
                                class="c20 c50 li-bullet-0"
                                style={{
                                    textAlign: 'center',
                                    marginLeft: '0',
                                    paddingLeft: '0 '
                                }}
                            >
                                <span class="c0">НАОД</span>
                            </li>
                        </ol>
                        <ol class="c48 lst-kix_list_2-0 start" start="1">
                            <li class="c20 c80 li-bullet-1">
                                <span class="c0">Здравствена состојба/очекувања на родителите (описно)</span>
                            </li>
                        </ol>
                        <a id="t.6386897a4fad3e02365cb0eff164c6768e559e31"></a>
                        <a id="t.2"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c31">
                                    <td class="c62 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Според родителот и/или децата</span>
                                        </p>
                                    </td>
                                    <td class="c11" colspan="1" rowspan="1" id="2_1_spored_roditelot_i_decata">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_1_spored_roditelot_i_decata"
                                            subject_details={this.state.subject_details}
                                            field={'2_1_spored_roditelot_i_decata'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c35">
                                    <td class="c32 c62" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Дијагноза </span>
                                        </p>
                                        <p class="c2">
                                            <span class="c0">(по МКБ-10)</span>
                                        </p>
                                    </td>
                                    <td class="c11" colspan="1" rowspan="1" id="2_1_dijagnoza">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_diagnoses_according_to_icd10'}
                                            className="sbj_tag sbj_key_2_1_dijagnoza"
                                            subject_details={this.state.subject_details}
                                            field={'2_1_dijagnoza'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c35">
                                    <td class="c62 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Сегашни третмани</span>
                                        </p>
                                    </td>
                                    <td class="c11" colspan="1" rowspan="1" id="2_1_segasni_tretmani">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_1_segasni_tretmani"
                                            subject_details={this.state.subject_details}
                                            field={'2_1_segasni_tretmani'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c107">
                                    <td class="c62 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Очекувања на родителите или детето</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c11"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_1_ocekuvanja_na_roditelite_ili_deteto"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            read_only={this.state.read_only}
                                            className="sbj_mtb sbj_key_2_1_ocekuvanja_na_roditelite_ili_deteto"
                                            subject_details={this.state.subject_details}
                                            field={'2_1_ocekuvanja_na_roditelite_ili_deteto'}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_2-0" start="2">
                            <li class="c20 c80 li-bullet-1">
                                <span class="c0">Лични аспекти и анамнеза (описно)</span>
                            </li>
                        </ol>
                        <a id="t.cc577d977a379b942d075f13e69b8cde3f17c84f"></a>
                        <a id="t.3"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c85">
                                    <td class="c32 c68" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">
                                                Лични особини на детето ( возраст, пол и други карактеристики)
                                            </span>
                                        </p>
                                    </td>
                                    <td class="c33" colspan="1" rowspan="1" id="2_2_licni_osobini_na_deteto">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_2_licni_osobini_na_deteto"
                                            subject_details={this.state.subject_details}
                                            field={'2_2_licni_osobini_na_deteto'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c74">
                                    <td class="c68 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">
                                                Анамнеза од родител, старател и лицето и од медицинска документација
                                            </span>
                                        </p>
                                    </td>
                                    <td
                                        class="c33"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_2_anamneza_od_roditel_staratel_i_liceto_i_od_medicinska_dokumentacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_2_anamneza_od_roditel_staratel_i_liceto_i_od_medicinska_dokumentacija"
                                            subject_details={this.state.subject_details}
                                            field={
                                                '2_2_anamneza_od_roditel_staratel_i_liceto_i_od_medicinska_dokumentacija'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr class="c100">
                                    <td class="c68 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Развојни фази</span>
                                        </p>
                                    </td>
                                    <td class="c33" colspan="1" rowspan="1" id="2_2_razvojni_fazi">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_2_razvojni_fazi"
                                            subject_details={this.state.subject_details}
                                            field={'2_2_razvojni_fazi'}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_2-0" start="3">
                            <li class="c20 c80 li-bullet-1">
                                <span class="c0">Животна средина (описно)</span>
                            </li>
                        </ol>
                        <a id="t.f2b1844325b97be7d847299fae52e3e7d75c6e8f"></a>
                        <a id="t.4"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c117">
                                    <td class="c68 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Потреба од производи и технологија (е1)</span>
                                        </p>
                                    </td>
                                    <td class="c33" colspan="1" rowspan="1" id="2_3_potreba_od_proizvodi_i_tehnologija">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_3_potreba_od_proizvodi_i_tehnologija"
                                            subject_details={this.state.subject_details}
                                            field={'2_3_potreba_od_proizvodi_i_tehnologija'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c65">
                                    <td class="c68 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Природно опкружување (е2)</span>
                                        </p>
                                    </td>
                                    <td class="c33" colspan="1" rowspan="1" id="2_3_prirodno_opkruzuvanje">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_3_prirodno_opkruzuvanje"
                                            subject_details={this.state.subject_details}
                                            field={'2_3_prirodno_opkruzuvanje'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c65">
                                    <td class="c68 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Поддршка и односи (е3)</span>
                                        </p>
                                    </td>
                                    <td class="c33" colspan="1" rowspan="1" id="2_3_poddrska_i_odnosi">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_3_poddrska_i_odnosi"
                                            subject_details={this.state.subject_details}
                                            field={'2_3_poddrska_i_odnosi'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c53">
                                    <td class="c68 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Ставови (е4)</span>
                                        </p>
                                    </td>
                                    <td class="c33" colspan="1" rowspan="1" id="2_3_statovi">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_3_statovi"
                                            subject_details={this.state.subject_details}
                                            field={'2_3_statovi'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c65">
                                    <td class="c68 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Услуги</span>
                                        </p>
                                        <p class="c2">
                                            <span class="c0">системи и политики (е5)</span>
                                        </p>
                                    </td>
                                    <td class="c33" colspan="1" rowspan="1" id="2_3_uslugi_sistemi_i_politiki">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_3_uslugi_sistemi_i_politiki"
                                            subject_details={this.state.subject_details}
                                            field={'2_3_uslugi_sistemi_i_politiki'}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ol class="c48 lst-kix_list_2-0" start="4">
                            <li class="c20 c80 li-bullet-1">
                                <span class="c0">Телесни структури</span>
                            </li>
                        </ol>
                        <a id="t.38a59d84d52c0825cb31acc5011dd908d6bbbf5f"></a>
                        <a id="t.5"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c83">
                                    <td class="c22" colspan="2" rowspan="2">
                                        <p class="c2">
                                            <span class="c0">ОПСЕРВАЦИЈА</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c83"></tr>
                                <tr class="c79">
                                    <td class="c57 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Нервен систем (s1)</span>
                                        </p>
                                    </td>
                                    <td class="c23" colspan="1" rowspan="1" id="2_4_nerven_sistem">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_4_nerven_sistem"
                                            subject_details={this.state.subject_details}
                                            field={'2_4_nerven_sistem'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c95">
                                    <td class="c32 c57" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Уво, око и сродни структури (s2)</span>
                                        </p>
                                    </td>
                                    <td class="c23" colspan="1" rowspan="1" id="2_4_uvo_oko_i_srodni_strukturi">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_4_uvo_oko_i_srodni_strukturi"
                                            subject_details={this.state.subject_details}
                                            field={'2_4_uvo_oko_i_srodni_strukturi'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c7">
                                    <td class="c57 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Глас и говор (s3)</span>
                                        </p>
                                    </td>
                                    <td class="c23" colspan="1" rowspan="1" id="2_4_glas_i_govor">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_4_glas_i_govor"
                                            subject_details={this.state.subject_details}
                                            field={'2_4_glas_i_govor'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c59">
                                    <td class="c57 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">
                                                Кардиоваскуларен, имунолошки и респираторен систем (s4)
                                            </span>
                                        </p>
                                    </td>
                                    <td
                                        class="c23"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_4_kariovaskularen_imunoloski_i_respiratoren_sistem"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_4_kariovaskularen_imunoloski_i_respiratoren_sistem"
                                            subject_details={this.state.subject_details}
                                            field={'2_4_kariovaskularen_imunoloski_i_respiratoren_sistem'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c101">
                                    <td class="c57 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Дигестивен, метаболен и ендокрин систем (s5)</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c23"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_4_digestivan_metabolen_i_endokrin_sistem"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_4_digestivan_metabolen_i_endokrin_sistem"
                                            subject_details={this.state.subject_details}
                                            field={'2_4_digestivan_metabolen_i_endokrin_sistem'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c73">
                                    <td class="c57 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Урогенитален систем (s6)</span>
                                        </p>
                                    </td>
                                    <td class="c23" colspan="1" rowspan="1" id="2_4_urogenitalen_sistem">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_4_urogenitalen_sistem"
                                            subject_details={this.state.subject_details}
                                            field={'2_4_urogenitalen_sistem'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c65">
                                    <td class="c57 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Структури поврзани со движење(s7)</span>
                                        </p>
                                    </td>
                                    <td class="c23" colspan="1" rowspan="1" id="2_4_strukturi_povrzani_so_dvizenje">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_4_strukturi_povrzani_so_dvizenje"
                                            subject_details={this.state.subject_details}
                                            field={'2_4_strukturi_povrzani_so_dvizenje'}
                                        />
                                    </td>
                                </tr>
                                <tr class="c70">
                                    <td class="c57 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Кожа (s8) </span>
                                        </p>
                                    </td>
                                    <td class="c23" colspan="1" rowspan="1" id="2_4_koza">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_4_koza"
                                            subject_details={this.state.subject_details}
                                            field={'2_4_koza'}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_2-0" start="5">
                            <li class="c20 c80 li-bullet-1">
                                <span class="c0">Телесни функции</span>
                            </li>
                        </ol>
                        <a id="t.6fc3a4dc83f25e82ca2ce4f2571feceeec3fd5a5"></a>
                        <a id="t.6"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c96">
                                    <td class="c32 c114" colspan="2" rowspan="2">
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                        <p class="c2">
                                            <span class="c0">
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                &nbsp; &nbsp; &nbsp;ОПСЕРВАЦИЈА
                                            </span>
                                        </p>
                                    </td>
                                    <td class="c8" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">проценка</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c103">
                                    <td class="c27 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">МКФ код</span>
                                        </p>
                                    </td>
                                    <td class="c27 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Квалифи-катор</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c55">
                                    <td class="c32 c44" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Ментални функции (b1)</span>
                                        </p>
                                    </td>

                                    <td class="c38" colspan="1" rowspan="1" id="2_5_mentalni_funkcii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            read_only={this.state.read_only}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_5_mentalni_funkcii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_5_mentalni_funkcii_opservacija'}
                                        />
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1" id="2_5_mentalni_funkcii_mkfcode">
                                        <p class="c2" id="2_5_mentalni_funkcii_mkfcode_1">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_mentalni_funkcii_mkfcode_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_mkfcode_1'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_mentalni_funkcii_mkfcode_2">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_mentalni_funkcii_mkfcode_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_mkfcode_2'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_mentalni_funkcii_mkfcode_3">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_mentalni_funkcii_mkfcode_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_mkfcode_3'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_mentalni_funkcii_mkfcode_4">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_mentalni_funkcii_mkfcode_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_mkfcode_4'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_mentalni_funkcii_mkfcode_5">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_mentalni_funkcii_mkfcode_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_mkfcode_5'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1">
                                        <p class="c2" id="2_5_mentalni_funkcii_kvalifikator_1">
                                            <SubjectSelect
                                                read_only={this.state.read_only}
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_mentalni_funkcii_kvalifikator_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_kvalifikator_1'}
                                                multiple={true}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_mentalni_funkcii_kvalifikator_2">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_mentalni_funkcii_kvalifikator_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_kvalifikator_2'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_mentalni_funkcii_kvalifikator_3">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_mentalni_funkcii_kvalifikator_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_kvalifikator_3'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_mentalni_funkcii_kvalifikator_4">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_mentalni_funkcii_kvalifikator_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_kvalifikator_4'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_mentalni_funkcii_kvalifikator_5">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_mentalni_funkcii_kvalifikator_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_mentalni_funkcii_kvalifikator_5'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c55">
                                    <td class="c44 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span>Сетилни функции (</span>
                                            <span>b2</span>
                                            <span class="c0">)</span>
                                        </p>
                                    </td>
                                    <td class="c63" colspan="1" rowspan="1" id="2_5_setilni_funkcii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_5_setilni_funkcii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_5_setilni_funkcii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1" id="2_5_setilni_funkcii_mkfcode">
                                        <p class="c2" id="2_5_setilni_funkcii_mkfcode_1">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_setilni_funkcii_mkfcode_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_mkfcode_1'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_setilni_funkcii_mkfcode_2">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_setilni_funkcii_mkfcode_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_mkfcode_2'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_setilni_funkcii_mkfcode_3">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_setilni_funkcii_mkfcode_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_mkfcode_3'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_setilni_funkcii_mkfcode_4">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_setilni_funkcii_mkfcode_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_mkfcode_4'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_setilni_funkcii_mkfcode_5">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_setilni_funkcii_mkfcode_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_mkfcode_5'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1">
                                        <p class="c2" id="2_5_setilni_funkcii_kvalifikator_1">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_setilni_funkcii_kvalifikator_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_kvalifikator_1'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_setilni_funkcii_kvalifikator_2">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_setilni_funkcii_kvalifikator_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_kvalifikator_2'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_setilni_funkcii_kvalifikator_3">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_setilni_funkcii_kvalifikator_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_kvalifikator_3'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_setilni_funkcii_kvalifikator_4">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_setilni_funkcii_kvalifikator_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_kvalifikator_4'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_setilni_funkcii_kvalifikator_5">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_setilni_funkcii_kvalifikator_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_setilni_funkcii_kvalifikator_5'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c55">
                                    <td class="c44 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Функции на глас и говор (b3)</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c63"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_5_funkcii_na_glas_i_govor_opservacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_5_funkcii_na_glas_i_govor_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_5_funkcii_na_glas_i_govor_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1" id="2_5_funkcii_na_glas_i_govor_mkfcode">
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_mkfcode_1">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_glas_i_govor_mkfcode_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_mkfcode_1'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_mkfcode_2">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_glas_i_govor_mkfcode_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_mkfcode_2'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_mkfcode_3">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_glas_i_govor_mkfcode_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_mkfcode_3'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_mkfcode_4">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_glas_i_govor_mkfcode_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_mkfcode_4'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_mkfcode_5">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_glas_i_govor_mkfcode_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_mkfcode_5'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1">
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_kvalifikator_1">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_glas_i_govor_kvalifikator_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_kvalifikator_1'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_kvalifikator_2">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_glas_i_govor_kvalifikator_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_kvalifikator_2'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_kvalifikator_3">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_glas_i_govor_kvalifikator_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_kvalifikator_3'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_kvalifikator_4">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_glas_i_govor_kvalifikator_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_kvalifikator_4'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_glas_i_govor_kvalifikator_5">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_glas_i_govor_kvalifikator_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_glas_i_govor_kvalifikator_5'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c55">
                                    <td class="c44 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Кардио васкуларни функции (b4)</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c63"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_5_kardio_vaskularni_funkcii_opservacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_5_kardio_vaskularni_funkcii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_5_kardio_vaskularni_funkcii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1" id="2_5_kardio_vaskularni_funkcii_mkfcode">
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_mkfcode_1">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_kardio_vaskularni_funkcii_mkfcode_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_mkfcode_1'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_mkfcode_2">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_kardio_vaskularni_funkcii_mkfcode_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_mkfcode_2'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_mkfcode_3">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_kardio_vaskularni_funkcii_mkfcode_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_mkfcode_3'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_mkfcode_4">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_kardio_vaskularni_funkcii_mkfcode_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_mkfcode_4'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_mkfcode_5">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_kardio_vaskularni_funkcii_mkfcode_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_mkfcode_5'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1">
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_kvalifikator_1">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_kardio_vaskularni_funkcii_kvalifikator_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_kvalifikator_1'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_kvalifikator_2">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_kardio_vaskularni_funkcii_kvalifikator_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_kvalifikator_2'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_kvalifikator_3">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_kardio_vaskularni_funkcii_kvalifikator_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_kvalifikator_3'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_kvalifikator_4">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_kardio_vaskularni_funkcii_kvalifikator_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_kvalifikator_4'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_kardio_vaskularni_funkcii_kvalifikator_5">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_kardio_vaskularni_funkcii_kvalifikator_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_kardio_vaskularni_funkcii_kvalifikator_5'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c55">
                                    <td class="c44 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Метаболизам (b5)</span>
                                        </p>
                                    </td>
                                    <td class="c63" colspan="1" rowspan="1" id="2_5_metabolizam_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_5_metabolizam_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_5_metabolizam_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1" id="2_5_metabolizam_mkfcode">
                                        <p class="c2" id="2_5_metabolizam_mkfcode_1">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_metabolizam_mkfcode_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_mkfcode_1'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_metabolizam_mkfcode_2">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_metabolizam_mkfcode_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_mkfcode_2'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_metabolizam_mkfcode_3">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_metabolizam_mkfcode_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_mkfcode_3'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_metabolizam_mkfcode_4">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_metabolizam_mkfcode_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_mkfcode_4'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_metabolizam_mkfcode_5">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_metabolizam_mkfcode_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_mkfcode_5'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1">
                                        <p class="c2" id="2_5_metabolizam_kvalifikator_1">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_metabolizam_kvalifikator_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_kvalifikator_1'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_metabolizam_kvalifikator_2">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_metabolizam_kvalifikator_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_kvalifikator_2'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_metabolizam_kvalifikator_3">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_metabolizam_kvalifikator_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_kvalifikator_3'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_metabolizam_kvalifikator_4">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_metabolizam_kvalifikator_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_kvalifikator_4'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_metabolizam_kvalifikator_5">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_metabolizam_kvalifikator_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_metabolizam_kvalifikator_5'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c55">
                                    <td class="c44 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Урогенитални функции (b6)</span>
                                        </p>
                                    </td>
                                    <td class="c63" colspan="1" rowspan="1" id="2_5_urogentialni_funkcii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_5_urogentialni_funkcii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_5_urogentialni_funkcii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1" id="2_5_urogentialni_funkcii_mkfcode">
                                        <p class="c2" id="2_5_urogentialni_funkcii_mkfcode_1">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_urogentialni_funkcii_mkfcode_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_mkfcode_1'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_urogentialni_funkcii_mkfcode_2">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_urogentialni_funkcii_mkfcode_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_mkfcode_2'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_urogentialni_funkcii_mkfcode_3">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_urogentialni_funkcii_mkfcode_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_mkfcode_3'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_urogentialni_funkcii_mkfcode_4">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_urogentialni_funkcii_mkfcode_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_mkfcode_4'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_urogentialni_funkcii_mkfcode_5">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_urogentialni_funkcii_mkfcode_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_mkfcode_5'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1">
                                        <p class="c2" id="2_5_urogentialni_funkcii_kvalifikator_1">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_urogentialni_funkcii_kvalifikator_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_kvalifikator_1'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_urogentialni_funkcii_kvalifikator_2">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_urogentialni_funkcii_kvalifikator_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_kvalifikator_2'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_urogentialni_funkcii_kvalifikator_3">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_urogentialni_funkcii_kvalifikator_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_kvalifikator_3'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_urogentialni_funkcii_kvalifikator_4">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_urogentialni_funkcii_kvalifikator_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_kvalifikator_4'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_urogentialni_funkcii_kvalifikator_5">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_urogentialni_funkcii_kvalifikator_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_urogentialni_funkcii_kvalifikator_5'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c55">
                                    <td class="c44 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Невромускулни функции (b7)</span>
                                        </p>
                                    </td>
                                    <td class="c63" colspan="1" rowspan="1" id="2_5_nevromuskulni_funkcii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_5_nevromuskulni_funkcii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_5_nevromuskulni_funkcii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1" id="2_5_nevromuskulni_funkcii_mkfcode">
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_mkfcode_1">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_nevromuskulni_funkcii_mkfcode_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_mkfcode_1'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_mkfcode_2">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_nevromuskulni_funkcii_mkfcode_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_mkfcode_2'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_mkfcode_3">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_nevromuskulni_funkcii_mkfcode_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_mkfcode_3'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_mkfcode_4">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_nevromuskulni_funkcii_mkfcode_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_mkfcode_4'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_mkfcode_5">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_nevromuskulni_funkcii_mkfcode_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_mkfcode_5'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1">
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_kvalifikator_1">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_nevromuskulni_funkcii_kvalifikator_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_kvalifikator_1'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_kvalifikator_2">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_nevromuskulni_funkcii_kvalifikator_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_kvalifikator_2'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_kvalifikator_3">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_nevromuskulni_funkcii_kvalifikator_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_kvalifikator_3'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_kvalifikator_4">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_nevromuskulni_funkcii_kvalifikator_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_kvalifikator_4'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_nevromuskulni_funkcii_kvalifikator_5">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_nevromuskulni_funkcii_kvalifikator_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_nevromuskulni_funkcii_kvalifikator_5'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c55">
                                    <td class="c44 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Функции на кожа (b8)</span>
                                        </p>
                                    </td>
                                    <td class="c63" colspan="1" rowspan="1" id="2_5_funkcii_na_koza_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_5_funkcii_na_koza_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_5_funkcii_na_koza_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1" id="2_5_funkcii_na_koza_mkfcode">
                                        <p class="c2" id="2_5_funkcii_na_koza_mkfcode_1">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_koza_mkfcode_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_mkfcode_1'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_koza_mkfcode_2">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_koza_mkfcode_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_mkfcode_2'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_koza_mkfcode_3">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_koza_mkfcode_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_mkfcode_3'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_koza_mkfcode_4">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_koza_mkfcode_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_mkfcode_4'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_koza_mkfcode_5">
                                            <SubjectTag
                                                reference={
                                                    'remote.view_code_setup_codesbody_functions_according_to_icf'
                                                }
                                                className="sbj_tag sbj_key_2_5_funkcii_na_koza_mkfcode_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_mkfcode_5'}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                    <td class="c27" colspan="1" rowspan="1">
                                        <p class="c2" id="2_5_funkcii_na_koza_kvalifikator_1">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_koza_kvalifikator_1"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_kvalifikator_1'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_koza_kvalifikator_2">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_koza_kvalifikator_2"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_kvalifikator_2'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_koza_kvalifikator_3">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_koza_kvalifikator_3"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_kvalifikator_3'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_koza_kvalifikator_4">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_koza_kvalifikator_4"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_kvalifikator_4'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                        <p class="c2" id="2_5_funkcii_na_koza_kvalifikator_5">
                                            <SubjectSelect
                                                reference={'remote.view_code_setup_icf_qualifier'}
                                                className="sbj_ms sbj_key_2_5_funkcii_na_koza_kvalifikator_5"
                                                subject_details={this.state.subject_details}
                                                field={'2_5_funkcii_na_koza_kvalifikator_5'}
                                                multiple={true}
                                                read_only={this.state.read_only}
                                            />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_2-0" start="6">
                            <li class="c20 c80 li-bullet-1">
                                <span class="c0">Учество</span>
                            </li>
                        </ol>
                        <a id="t.57619be73d871b3d92460f0e883e9fe3c5c81464"></a>
                        <a id="t.7"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c42">
                                    <td class="c5" colspan="1" rowspan="2">
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                    </td>
                                    <td class="c1 c32" colspan="1" rowspan="2">
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                        <p class="c2">
                                            <span class="c0">ОПСЕРВАЦИЈА</span>
                                        </p>
                                    </td>
                                    <td class="c32 c105" colspan="3" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">проценка</span>
                                        </p>
                                    </td>
                                    <td class="c32 c86" colspan="4" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">улога на околината</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c108">
                                    <td class="c12 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">МКФ код</span>
                                        </p>
                                    </td>
                                    <td class="c13 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Квалифи-катор</span>
                                        </p>
                                    </td>
                                    <td class="c16 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Индикатор &nbsp;</span>
                                        </p>
                                    </td>
                                    <td class="c17 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Олесну-вач</span>
                                        </p>
                                    </td>
                                    <td class="c40 c32" colspan="2" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Бариера </span>
                                        </p>
                                    </td>
                                    <td class="c14 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Спецификација</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c43">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Учење (d1)</span>
                                        </p>
                                    </td>
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_1_1_ucenje_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_1_1_ucenje_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_1_ucenje_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_1_1_ucenje_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_1_1_ucenje_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_1_ucenje_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_1_1_ucenje_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_1_1_ucenje_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_1_ucenje_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_1_1_ucenje_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_1_ucenje_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_1_ucenje_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_1_1_ucenje_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_1_ucenje_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_1_ucenje_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_1_1_ucenje_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_1_1_ucenje_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_1_ucenje_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c14" colspan="1" rowspan="6" id="2_6_1_ucenje_specifikacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_1_ucenje_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_ucenje_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c43">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_1_2_ucenje_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_1_2_ucenje_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_2_ucenje_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_1_2_ucenje_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_1_2_ucenje_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_2_ucenje_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_1_2_ucenje_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_1_2_ucenje_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_2_ucenje_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_1_2_ucenje_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_2_ucenje_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_2_ucenje_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_1_2_ucenje_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_2_ucenje_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_2_ucenje_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_1_2_ucenje_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_1_2_ucenje_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_2_ucenje_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c43">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_1_3_ucenje_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_1_3_ucenje_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_3_ucenje_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_1_3_ucenje_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_1_3_ucenje_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_3_ucenje_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_1_3_ucenje_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_1_3_ucenje_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_3_ucenje_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_1_3_ucenje_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_3_ucenje_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_3_ucenje_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_1_3_ucenje_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_3_ucenje_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_3_ucenje_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_1_3_ucenje_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_1_3_ucenje_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_3_ucenje_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c43">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_1_4_ucenje_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_1_4_ucenje_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_4_ucenje_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_1_4_ucenje_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_1_4_ucenje_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_4_ucenje_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_1_4_ucenje_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_1_4_ucenje_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_4_ucenje_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_1_4_ucenje_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_4_ucenje_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_4_ucenje_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_1_4_ucenje_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_4_ucenje_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_4_ucenje_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_1_4_ucenje_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_1_4_ucenje_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_4_ucenje_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c43">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_1_5_ucenje_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_1_5_ucenje_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_5_ucenje_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_1_5_ucenje_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_1_5_ucenje_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_5_ucenje_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_1_5_ucenje_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_1_5_ucenje_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_5_ucenje_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_1_5_ucenje_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_5_ucenje_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_5_ucenje_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_1_5_ucenje_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_5_ucenje_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_5_ucenje_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_1_5_ucenje_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_1_5_ucenje_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_5_ucenje_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c43">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_1_6_ucenje_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_1_6_ucenje_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_6_ucenje_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_1_6_ucenje_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_1_6_ucenje_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_6_ucenje_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_1_6_ucenje_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_1_6_ucenje_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_6_ucenje_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_1_6_ucenje_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_6_ucenje_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_6_ucenje_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_1_6_ucenje_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_1_6_ucenje_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_6_ucenje_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_1_6_ucenje_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_1_6_ucenje_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_1_6_ucenje_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>

                                <tr class="c26">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Општи задачи (d2)</span>
                                        </p>
                                    </td>
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_2_1_opsti_zadaci_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_2_1_opsti_zadaci_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_1_opsti_zadaci_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_2_1_opsti_zadaci_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_2_1_opsti_zadaci_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_1_opsti_zadaci_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_2_1_opsti_zadaci_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_2_1_opsti_zadaci_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_1_opsti_zadaci_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_2_1_opsti_zadaci_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_1_opsti_zadaci_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_1_opsti_zadaci_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_2_1_opsti_zadaci_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_1_opsti_zadaci_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_1_opsti_zadaci_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_2_1_opsti_zadaci_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_2_1_opsti_zadaci_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_1_opsti_zadaci_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c14" colspan="1" rowspan="6" id="2_6_2_opsti_zadaci_specifikacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_2_opsti_zadaci_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_opsti_zadaci_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_2_2_opsti_zadaci_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_2_2_opsti_zadaci_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_2_opsti_zadaci_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_2_2_opsti_zadaci_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_2_2_opsti_zadaci_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_2_opsti_zadaci_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_2_2_opsti_zadaci_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_2_2_opsti_zadaci_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_2_opsti_zadaci_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_2_2_opsti_zadaci_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_2_opsti_zadaci_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_2_opsti_zadaci_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_2_2_opsti_zadaci_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_2_opsti_zadaci_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_2_opsti_zadaci_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_2_2_opsti_zadaci_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_2_2_opsti_zadaci_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_2_opsti_zadaci_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_2_3_opsti_zadaci_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_2_3_opsti_zadaci_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_3_opsti_zadaci_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_2_3_opsti_zadaci_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_2_3_opsti_zadaci_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_3_opsti_zadaci_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_2_3_opsti_zadaci_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_2_3_opsti_zadaci_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_3_opsti_zadaci_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_2_3_opsti_zadaci_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_3_opsti_zadaci_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_3_opsti_zadaci_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_2_3_opsti_zadaci_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_3_opsti_zadaci_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_3_opsti_zadaci_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_2_3_opsti_zadaci_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_2_3_opsti_zadaci_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_3_opsti_zadaci_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_2_4_opsti_zadaci_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_2_4_opsti_zadaci_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_4_opsti_zadaci_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_2_4_opsti_zadaci_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_2_4_opsti_zadaci_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_4_opsti_zadaci_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_2_4_opsti_zadaci_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_2_4_opsti_zadaci_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_4_opsti_zadaci_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_2_4_opsti_zadaci_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_4_opsti_zadaci_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_4_opsti_zadaci_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_2_4_opsti_zadaci_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_4_opsti_zadaci_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_4_opsti_zadaci_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_2_4_opsti_zadaci_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_2_4_opsti_zadaci_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_4_opsti_zadaci_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_2_5_opsti_zadaci_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_2_5_opsti_zadaci_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_5_opsti_zadaci_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_2_5_opsti_zadaci_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_2_5_opsti_zadaci_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_5_opsti_zadaci_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_2_5_opsti_zadaci_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_2_5_opsti_zadaci_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_5_opsti_zadaci_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_2_5_opsti_zadaci_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_5_opsti_zadaci_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_5_opsti_zadaci_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_2_5_opsti_zadaci_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_5_opsti_zadaci_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_5_opsti_zadaci_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_2_5_opsti_zadaci_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_2_5_opsti_zadaci_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_5_opsti_zadaci_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_2_6_opsti_zadaci_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_2_6_opsti_zadaci_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_6_opsti_zadaci_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_2_6_opsti_zadaci_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_2_6_opsti_zadaci_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_6_opsti_zadaci_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_2_6_opsti_zadaci_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_2_6_opsti_zadaci_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_6_opsti_zadaci_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_2_6_opsti_zadaci_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_6_opsti_zadaci_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_6_opsti_zadaci_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_2_6_opsti_zadaci_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_2_6_opsti_zadaci_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_6_opsti_zadaci_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_2_6_opsti_zadaci_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_2_6_opsti_zadaci_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_2_6_opsti_zadaci_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>

                                <tr class="c26">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Комуни-кација (d3)</span>
                                        </p>
                                    </td>
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_3_1_komunikacija_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_3_1_komunikacija_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_1_komunikacija_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_3_1_komunikacija_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_3_1_komunikacija_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_1_komunikacija_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_3_1_komunikacija_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_3_1_komunikacija_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_1_komunikacija_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_3_1_komunikacija_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_1_komunikacija_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_1_komunikacija_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_3_1_komunikacija_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_1_komunikacija_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_1_komunikacija_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_3_1_komunikacija_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_3_1_komunikacija_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_1_komunikacija_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c14" colspan="1" rowspan="6" id="2_6_3_komunikacija_specifikacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_3_komunikacija_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_komunikacija_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_3_2_komunikacija_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_3_2_komunikacija_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_2_komunikacija_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_3_2_komunikacija_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_3_2_komunikacija_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_2_komunikacija_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_3_2_komunikacija_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_3_2_komunikacija_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_2_komunikacija_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_3_2_komunikacija_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_2_komunikacija_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_2_komunikacija_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_3_2_komunikacija_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_2_komunikacija_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_2_komunikacija_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_3_2_komunikacija_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_3_2_komunikacija_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_2_komunikacija_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c53">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_3_3_komunikacija_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_3_3_komunikacija_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_3_komunikacija_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_3_3_komunikacija_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_3_3_komunikacija_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_3_komunikacija_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_3_3_komunikacija_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_3_3_komunikacija_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_3_komunikacija_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_3_3_komunikacija_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_3_komunikacija_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_3_komunikacija_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_3_3_komunikacija_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_3_komunikacija_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_3_komunikacija_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_3_3_komunikacija_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_3_3_komunikacija_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_3_komunikacija_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_3_4_komunikacija_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_3_4_komunikacija_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_4_komunikacija_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_3_4_komunikacija_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_3_4_komunikacija_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_4_komunikacija_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_3_4_komunikacija_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_3_4_komunikacija_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_4_komunikacija_kvalifikator'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_3_4_komunikacija_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_4_komunikacija_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_4_komunikacija_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_3_4_komunikacija_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_4_komunikacija_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_4_komunikacija_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_3_4_komunikacija_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_3_4_komunikacija_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_4_komunikacija_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_3_5_komunikacija_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_3_5_komunikacija_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_5_komunikacija_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_3_5_komunikacija_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_3_5_komunikacija_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_5_komunikacija_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_3_5_komunikacija_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_3_5_komunikacija_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_5_komunikacija_kvalifikator'}
                                            read_only={this.state.read_only}
                                            multiple={true}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_3_5_komunikacija_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_5_komunikacija_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_5_komunikacija_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_3_5_komunikacija_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_5_komunikacija_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_5_komunikacija_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_3_5_komunikacija_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_3_5_komunikacija_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_5_komunikacija_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_3_6_komunikacija_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_3_6_komunikacija_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_6_komunikacija_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_3_6_komunikacija_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_3_6_komunikacija_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_6_komunikacija_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_3_6_komunikacija_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_3_6_komunikacija_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_6_komunikacija_kvalifikator'}
                                            read_only={this.state.read_only}
                                            multiple={true}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_3_6_komunikacija_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_6_komunikacija_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_6_komunikacija_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_3_6_komunikacija_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_3_6_komunikacija_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_6_komunikacija_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_3_6_komunikacija_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_3_6_komunikacija_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_3_6_komunikacija_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>

                                <tr class="c45">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Мобил-</span>
                                        </p>
                                        <p class="c2">
                                            <span class="c0">ност (d4)</span>
                                        </p>
                                    </td>
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_4_1_mobilnost_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_4_1_mobilnost_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_1_mobilnost_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_4_1_mobilnost_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_4_1_mobilnost_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_1_mobilnost_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_4_1_mobilnost_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_4_1_mobilnost_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_1_mobilnost_kvalifikator'}
                                            read_only={this.state.read_only}
                                            multiple={true}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_4_1_mobilnost_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_1_mobilnost_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_1_mobilnost_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_4_1_mobilnost_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_1_mobilnost_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_1_mobilnost_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_4_1_mobilnost_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_4_1_mobilnost_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_1_mobilnost_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c14" colspan="1" rowspan="6" id="2_6_4_mobilnost_specifikacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_4_mobilnost_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_mobilnost_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c45">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_4_2_mobilnost_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_4_2_mobilnost_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_2_mobilnost_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_4_2_mobilnost_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_4_2_mobilnost_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_2_mobilnost_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_4_2_mobilnost_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_4_2_mobilnost_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_2_mobilnost_kvalifikator'}
                                            read_only={this.state.read_only}
                                            multiple={true}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_4_2_mobilnost_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_2_mobilnost_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_2_mobilnost_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_4_2_mobilnost_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_2_mobilnost_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_2_mobilnost_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_4_2_mobilnost_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_4_2_mobilnost_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_2_mobilnost_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c45">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_4_3_mobilnost_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_4_3_mobilnost_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_3_mobilnost_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_4_3_mobilnost_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_4_3_mobilnost_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_3_mobilnost_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_4_3_mobilnost_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_4_3_mobilnost_kvalifikator"
                                            multiple={true}
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_3_mobilnost_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_4_3_mobilnost_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_3_mobilnost_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_3_mobilnost_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_4_3_mobilnost_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_3_mobilnost_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_3_mobilnost_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_4_3_mobilnost_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_4_3_mobilnost_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_3_mobilnost_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c45">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_4_4_mobilnost_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_4_4_mobilnost_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_4_mobilnost_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_4_4_mobilnost_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_4_4_mobilnost_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_4_mobilnost_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_4_4_mobilnost_kvalifikator">
                                        <SubjectSelect
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            multiple={true}
                                            className="sbj_ms sbj_key_2_6_4_4_mobilnost_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_4_mobilnost_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_4_4_mobilnost_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_4_mobilnost_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_4_mobilnost_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_4_4_mobilnost_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_4_mobilnost_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_4_mobilnost_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_4_4_mobilnost_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_4_4_mobilnost_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_4_mobilnost_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c45">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_4_5_mobilnost_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_4_5_mobilnost_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_5_mobilnost_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_4_5_mobilnost_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_4_5_mobilnost_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_5_mobilnost_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_4_5_mobilnost_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_4_5_mobilnost_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_5_mobilnost_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_4_5_mobilnost_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_5_mobilnost_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_5_mobilnost_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_4_5_mobilnost_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_5_mobilnost_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_5_mobilnost_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_4_5_mobilnost_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_4_5_mobilnost_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_5_mobilnost_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c45">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_4_6_mobilnost_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_4_6_mobilnost_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_6_mobilnost_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_4_6_mobilnost_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_4_6_mobilnost_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_6_mobilnost_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_4_6_mobilnost_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_4_6_mobilnost_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_6_mobilnost_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_4_6_mobilnost_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_6_mobilnost_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_6_mobilnost_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_4_6_mobilnost_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_4_6_mobilnost_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_6_mobilnost_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_4_6_mobilnost_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_4_6_mobilnost_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_4_6_mobilnost_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>

                                <tr class="c9">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Грижа</span>
                                        </p>
                                        <p class="c2">
                                            <span class="c0">за себе (d5)</span>
                                        </p>
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                    </td>
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_5_1_griza_za_sebe_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_5_1_griza_za_sebe_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_1_griza_za_sebe_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_5_1_griza_za_sebe_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_5_1_griza_za_sebe_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_1_griza_za_sebe_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_5_1_griza_za_sebe_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_5_1_griza_za_sebe_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            read_only={this.state.read_only}
                                            field={'2_6_5_1_griza_za_sebe_kvalifikator'}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_5_1_griza_za_sebe_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_1_griza_za_sebe_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_1_griza_za_sebe_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_5_1_griza_za_sebe_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_1_griza_za_sebe_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_1_griza_za_sebe_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_5_1_griza_za_sebe_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_5_1_griza_za_sebe_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_1_griza_za_sebe_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c14" colspan="1" rowspan="6" id="2_6_5_griza_za_sebe_specifikacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_5_griza_za_sebe_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_griza_za_sebe_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_5_2_griza_za_sebe_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_5_2_griza_za_sebe_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_2_griza_za_sebe_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_5_2_griza_za_sebe_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_5_2_griza_za_sebe_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_2_griza_za_sebe_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_5_2_griza_za_sebe_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_5_2_griza_za_sebe_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_2_griza_za_sebe_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_5_2_griza_za_sebe_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_2_griza_za_sebe_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_2_griza_za_sebe_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_5_2_griza_za_sebe_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_2_griza_za_sebe_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_2_griza_za_sebe_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_5_2_griza_za_sebe_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_5_2_griza_za_sebe_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_2_griza_za_sebe_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_5_3_griza_za_sebe_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_5_3_griza_za_sebe_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_3_griza_za_sebe_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_5_3_griza_za_sebe_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_5_3_griza_za_sebe_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_3_griza_za_sebe_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_5_3_griza_za_sebe_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_5_3_griza_za_sebe_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_3_griza_za_sebe_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_5_3_griza_za_sebe_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_3_griza_za_sebe_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_3_griza_za_sebe_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_5_3_griza_za_sebe_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_3_griza_za_sebe_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_3_griza_za_sebe_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_5_3_griza_za_sebe_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_5_3_griza_za_sebe_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_3_griza_za_sebe_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_5_4_griza_za_sebe_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_5_4_griza_za_sebe_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_4_griza_za_sebe_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_5_4_griza_za_sebe_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_5_4_griza_za_sebe_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_4_griza_za_sebe_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_5_4_griza_za_sebe_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_5_4_griza_za_sebe_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_4_griza_za_sebe_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_5_4_griza_za_sebe_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_4_griza_za_sebe_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_4_griza_za_sebe_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_5_4_griza_za_sebe_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_4_griza_za_sebe_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_4_griza_za_sebe_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_5_4_griza_za_sebe_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_5_4_griza_za_sebe_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_4_griza_za_sebe_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_5_5_griza_za_sebe_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_5_5_griza_za_sebe_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_5_griza_za_sebe_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_5_5_griza_za_sebe_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_5_5_griza_za_sebe_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_5_griza_za_sebe_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_5_5_griza_za_sebe_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_5_5_griza_za_sebe_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_5_griza_za_sebe_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_5_5_griza_za_sebe_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_5_griza_za_sebe_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_5_griza_za_sebe_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_5_5_griza_za_sebe_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_5_griza_za_sebe_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_5_griza_za_sebe_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_5_5_griza_za_sebe_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_5_5_griza_za_sebe_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_5_griza_za_sebe_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_5_6_griza_za_sebe_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_5_6_griza_za_sebe_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_6_griza_za_sebe_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_5_6_griza_za_sebe_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_5_6_griza_za_sebe_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_6_griza_za_sebe_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_5_6_griza_za_sebe_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_5_6_griza_za_sebe_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_6_griza_za_sebe_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_5_6_griza_za_sebe_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_6_griza_za_sebe_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_6_griza_za_sebe_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_5_6_griza_za_sebe_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_5_6_griza_za_sebe_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_6_griza_za_sebe_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_5_6_griza_za_sebe_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_5_6_griza_za_sebe_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_5_6_griza_za_sebe_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>

                                <tr class="c26">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Живот</span>
                                        </p>
                                        <p class="c2">
                                            <span class="c0">во домот (d6)</span>
                                        </p>
                                    </td>
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_6_1_zivot_vo_domot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_6_1_zivot_vo_domot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_1_zivot_vo_domot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_6_1_zivot_vo_domot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_6_1_zivot_vo_domot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_1_zivot_vo_domot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_6_1_zivot_vo_domot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_6_1_zivot_vo_domot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_1_zivot_vo_domot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_6_1_zivot_vo_domot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_1_zivot_vo_domot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_1_zivot_vo_domot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_6_1_zivot_vo_domot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_1_zivot_vo_domot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_1_zivot_vo_domot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_6_1_zivot_vo_domot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_6_1_zivot_vo_domot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_1_zivot_vo_domot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c14" colspan="1" rowspan="6" id="2_6_6_zivot_vo_domot_specifikacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_6_zivot_vo_domot_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_zivot_vo_domot_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_6_2_zivot_vo_domot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_6_2_zivot_vo_domot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_2_zivot_vo_domot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_6_2_zivot_vo_domot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_6_2_zivot_vo_domot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_2_zivot_vo_domot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_6_2_zivot_vo_domot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_6_2_zivot_vo_domot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_2_zivot_vo_domot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_6_2_zivot_vo_domot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_2_zivot_vo_domot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_2_zivot_vo_domot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_6_2_zivot_vo_domot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_2_zivot_vo_domot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_2_zivot_vo_domot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_6_2_zivot_vo_domot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_6_2_zivot_vo_domot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_2_zivot_vo_domot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_6_3_zivot_vo_domot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_6_3_zivot_vo_domot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_3_zivot_vo_domot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_6_3_zivot_vo_domot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_6_3_zivot_vo_domot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_3_zivot_vo_domot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_6_3_zivot_vo_domot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_6_3_zivot_vo_domot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_3_zivot_vo_domot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_6_3_zivot_vo_domot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_3_zivot_vo_domot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_3_zivot_vo_domot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_6_3_zivot_vo_domot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_3_zivot_vo_domot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_3_zivot_vo_domot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_6_3_zivot_vo_domot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_6_3_zivot_vo_domot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_3_zivot_vo_domot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_6_4_zivot_vo_domot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_6_4_zivot_vo_domot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_4_zivot_vo_domot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_6_4_zivot_vo_domot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_6_4_zivot_vo_domot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_4_zivot_vo_domot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_6_4_zivot_vo_domot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_6_4_zivot_vo_domot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_4_zivot_vo_domot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_6_4_zivot_vo_domot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_4_zivot_vo_domot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_4_zivot_vo_domot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_6_4_zivot_vo_domot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_4_zivot_vo_domot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_4_zivot_vo_domot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_6_4_zivot_vo_domot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_6_4_zivot_vo_domot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_4_zivot_vo_domot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_6_5_zivot_vo_domot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_6_5_zivot_vo_domot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_5_zivot_vo_domot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_6_5_zivot_vo_domot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_6_5_zivot_vo_domot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_5_zivot_vo_domot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_6_5_zivot_vo_domot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_6_5_zivot_vo_domot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_5_zivot_vo_domot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_6_5_zivot_vo_domot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_5_zivot_vo_domot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_5_zivot_vo_domot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_6_5_zivot_vo_domot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_5_zivot_vo_domot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_5_zivot_vo_domot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_6_5_zivot_vo_domot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_6_5_zivot_vo_domot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_5_zivot_vo_domot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_6_6_zivot_vo_domot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_6_6_zivot_vo_domot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_6_zivot_vo_domot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_6_6_zivot_vo_domot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_6_6_zivot_vo_domot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_6_zivot_vo_domot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_6_6_zivot_vo_domot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_6_6_zivot_vo_domot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_6_zivot_vo_domot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_6_6_zivot_vo_domot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_6_zivot_vo_domot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_6_zivot_vo_domot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_6_6_zivot_vo_domot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_6_6_zivot_vo_domot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_6_zivot_vo_domot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_6_6_zivot_vo_domot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_6_6_zivot_vo_domot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_6_6_zivot_vo_domot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>

                                <tr class="c26">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Интеракции (d7)</span>
                                        </p>
                                    </td>
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_7_1_interakcijii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_7_1_interakcijii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_1_interakcijii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_7_1_interakcijii_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_7_1_interakcijii_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_1_interakcijii_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_7_1_interakcijii_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_7_1_interakcijii_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_1_interakcijii_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_7_1_interakcijii_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_1_interakcijii_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_1_interakcijii_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_7_1_interakcijii_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_1_interakcijii_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_1_interakcijii_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_7_1_interakcijii_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_7_1_interakcijii_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_1_interakcijii_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c14" colspan="1" rowspan="6" id="2_6_7_interakcijii_specifikacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_7_interakcijii_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_interakcijii_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_7_2_interakcijii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_7_2_interakcijii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_2_interakcijii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_7_2_interakcijii_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_7_2_interakcijii_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_2_interakcijii_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_7_2_interakcijii_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_7_2_interakcijii_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_2_interakcijii_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_7_2_interakcijii_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_2_interakcijii_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_2_interakcijii_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_7_2_interakcijii_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_2_interakcijii_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_2_interakcijii_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_7_2_interakcijii_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_7_2_interakcijii_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_2_interakcijii_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c79">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_7_3_interakcijii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_7_3_interakcijii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_3_interakcijii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_7_3_interakcijii_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_7_3_interakcijii_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_3_interakcijii_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_7_3_interakcijii_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_7_3_interakcijii_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_3_interakcijii_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_7_3_interakcijii_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_3_interakcijii_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_3_interakcijii_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_7_3_interakcijii_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_3_interakcijii_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_3_interakcijii_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_7_3_interakcijii_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_7_3_interakcijii_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_3_interakcijii_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_7_4_interakcijii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_7_4_interakcijii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_4_interakcijii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_7_4_interakcijii_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_7_4_interakcijii_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_4_interakcijii_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_7_4_interakcijii_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_7_4_interakcijii_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_4_interakcijii_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_7_4_interakcijii_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_4_interakcijii_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_4_interakcijii_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_7_4_interakcijii_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_4_interakcijii_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_4_interakcijii_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_7_4_interakcijii_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_7_4_interakcijii_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_4_interakcijii_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_7_5_interakcijii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_7_5_interakcijii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_5_interakcijii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_7_5_interakcijii_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_7_5_interakcijii_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_5_interakcijii_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_7_5_interakcijii_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_7_5_interakcijii_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_5_interakcijii_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_7_5_interakcijii_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_5_interakcijii_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_5_interakcijii_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_7_5_interakcijii_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_5_interakcijii_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_5_interakcijii_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_7_5_interakcijii_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_7_5_interakcijii_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_5_interakcijii_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_7_6_interakcijii_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_7_6_interakcijii_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_6_interakcijii_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_7_6_interakcijii_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_7_6_interakcijii_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_6_interakcijii_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_7_6_interakcijii_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_7_6_interakcijii_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_6_interakcijii_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_7_6_interakcijii_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_6_interakcijii_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_6_interakcijii_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_7_6_interakcijii_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_7_6_interakcijii_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_6_interakcijii_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c40" colspan="2" rowspan="1" id="2_6_7_6_interakcijii_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_7_6_interakcijii_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_7_6_interakcijii_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>

                                <tr class="c9">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Главни животни области (d8)</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c1"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_1_glavni_zivotni_oblasti_opservacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_8_1_glavni_zivotni_oblasti_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_1_glavni_zivotni_oblasti_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_8_1_glavni_zivotni_oblasti_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_8_1_glavni_zivotni_oblasti_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_1_glavni_zivotni_oblasti_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c13"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_1_glavni_zivotni_oblasti_kvalifikator"
                                    >
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_8_1_glavni_zivotni_oblasti_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_1_glavni_zivotni_oblasti_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td
                                        class="c16"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_1_glavni_zivotni_oblasti_indikator"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_1_glavni_zivotni_oblasti_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_1_glavni_zivotni_oblasti_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c17"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_1_glavni_zivotni_oblasti_olesnu_vac"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_1_glavni_zivotni_oblasti_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_1_glavni_zivotni_oblasti_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_8_1_glavni_zivotni_oblasti_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_8_1_glavni_zivotni_oblasti_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_1_glavni_zivotni_oblasti_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td
                                        class="c88"
                                        colspan="2"
                                        rowspan="6"
                                        id="2_6_8_glavni_zivotni_oblasti_specifikacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_8_glavni_zivotni_oblasti_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_glavni_zivotni_oblasti_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td
                                        class="c1"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_2_glavni_zivotni_oblasti_opservacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_8_2_glavni_zivotni_oblasti_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_2_glavni_zivotni_oblasti_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_8_2_glavni_zivotni_oblasti_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_8_2_glavni_zivotni_oblasti_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_2_glavni_zivotni_oblasti_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c13"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_2_glavni_zivotni_oblasti_kvalifikator"
                                    >
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_8_2_glavni_zivotni_oblasti_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_2_glavni_zivotni_oblasti_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td
                                        class="c16"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_2_glavni_zivotni_oblasti_indikator"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_2_glavni_zivotni_oblasti_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_2_glavni_zivotni_oblasti_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c17"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_2_glavni_zivotni_oblasti_olesnu_vac"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_2_glavni_zivotni_oblasti_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_2_glavni_zivotni_oblasti_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_8_2_glavni_zivotni_oblasti_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_8_2_glavni_zivotni_oblasti_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_2_glavni_zivotni_oblasti_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td
                                        class="c1"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_3_glavni_zivotni_oblasti_opservacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_8_3_glavni_zivotni_oblasti_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_3_glavni_zivotni_oblasti_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_8_3_glavni_zivotni_oblasti_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_8_3_glavni_zivotni_oblasti_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_3_glavni_zivotni_oblasti_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c13"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_3_glavni_zivotni_oblasti_kvalifikator"
                                    >
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_8_3_glavni_zivotni_oblasti_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_3_glavni_zivotni_oblasti_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td
                                        class="c16"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_3_glavni_zivotni_oblasti_indikator"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_3_glavni_zivotni_oblasti_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_3_glavni_zivotni_oblasti_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c17"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_3_glavni_zivotni_oblasti_olesnu_vac"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_3_glavni_zivotni_oblasti_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_3_glavni_zivotni_oblasti_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_8_3_glavni_zivotni_oblasti_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_8_3_glavni_zivotni_oblasti_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_3_glavni_zivotni_oblasti_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td
                                        class="c1"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_4_glavni_zivotni_oblasti_opservacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_8_4_glavni_zivotni_oblasti_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_4_glavni_zivotni_oblasti_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_8_4_glavni_zivotni_oblasti_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_8_4_glavni_zivotni_oblasti_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_4_glavni_zivotni_oblasti_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c13"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_4_glavni_zivotni_oblasti_kvalifikator"
                                    >
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_8_4_glavni_zivotni_oblasti_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_4_glavni_zivotni_oblasti_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td
                                        class="c16"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_4_glavni_zivotni_oblasti_indikator"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_4_glavni_zivotni_oblasti_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_4_glavni_zivotni_oblasti_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c17"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_4_glavni_zivotni_oblasti_olesnu_vac"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_4_glavni_zivotni_oblasti_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_4_glavni_zivotni_oblasti_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_8_4_glavni_zivotni_oblasti_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_8_4_glavni_zivotni_oblasti_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_4_glavni_zivotni_oblasti_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td
                                        class="c1"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_5_glavni_zivotni_oblasti_opservacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_8_5_glavni_zivotni_oblasti_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_5_glavni_zivotni_oblasti_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_8_5_glavni_zivotni_oblasti_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_8_5_glavni_zivotni_oblasti_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_5_glavni_zivotni_oblasti_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c13"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_5_glavni_zivotni_oblasti_kvalifikator"
                                    >
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_8_5_glavni_zivotni_oblasti_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_5_glavni_zivotni_oblasti_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td
                                        class="c16"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_5_glavni_zivotni_oblasti_indikator"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_5_glavni_zivotni_oblasti_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_5_glavni_zivotni_oblasti_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c17"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_5_glavni_zivotni_oblasti_olesnu_vac"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_5_glavni_zivotni_oblasti_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_5_glavni_zivotni_oblasti_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_8_5_glavni_zivotni_oblasti_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_8_5_glavni_zivotni_oblasti_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_5_glavni_zivotni_oblasti_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c9">
                                    <td
                                        class="c1"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_6_glavni_zivotni_oblasti_opservacija"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_8_6_glavni_zivotni_oblasti_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_6_glavni_zivotni_oblasti_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_8_6_glavni_zivotni_oblasti_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_8_6_glavni_zivotni_oblasti_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_6_glavni_zivotni_oblasti_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c13"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_6_glavni_zivotni_oblasti_kvalifikator"
                                    >
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_8_6_glavni_zivotni_oblasti_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_6_glavni_zivotni_oblasti_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td
                                        class="c16"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_6_glavni_zivotni_oblasti_indikator"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_6_glavni_zivotni_oblasti_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_6_glavni_zivotni_oblasti_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c17"
                                        colspan="1"
                                        rowspan="1"
                                        id="2_6_8_6_glavni_zivotni_oblasti_olesnu_vac"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_8_6_glavni_zivotni_oblasti_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_6_glavni_zivotni_oblasti_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_8_6_glavni_zivotni_oblasti_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_8_6_glavni_zivotni_oblasti_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_8_6_glavni_zivotni_oblasti_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>

                                <tr class="c26">
                                    <td class="c5" colspan="1" rowspan="6">
                                        <p class="c2">
                                            <span class="c0">Социјален живот (d9)</span>
                                        </p>
                                    </td>
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_9_1_socijalen_zivot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_9_1_socijalen_zivot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_1_socijalen_zivot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_9_1_socijalen_zivot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_9_1_socijalen_zivot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_1_socijalen_zivot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_9_1_socijalen_zivot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_9_1_socijalen_zivot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_1_socijalen_zivot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_9_1_socijalen_zivot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_1_socijalen_zivot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_1_socijalen_zivot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_9_1_socijalen_zivot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_1_socijalen_zivot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_1_socijalen_zivot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_9_1_socijalen_zivot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_9_1_socijalen_zivot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_1_socijalen_zivot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c88" colspan="2" rowspan="6" id="2_6_9_socijalen_zivot_specifikacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_9_socijalen_zivot_specifikacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_socijalen_zivot_specifikacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_9_2_socijalen_zivot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_9_2_socijalen_zivot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_2_socijalen_zivot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_9_2_socijalen_zivot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_9_2_socijalen_zivot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_2_socijalen_zivot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_9_2_socijalen_zivot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_9_2_socijalen_zivot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_2_socijalen_zivot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_9_2_socijalen_zivot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_2_socijalen_zivot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_2_socijalen_zivot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_9_2_socijalen_zivot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_2_socijalen_zivot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_2_socijalen_zivot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_9_2_socijalen_zivot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_9_2_socijalen_zivot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_2_socijalen_zivot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_9_3_socijalen_zivot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_9_3_socijalen_zivot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_3_socijalen_zivot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_9_3_socijalen_zivot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_9_3_socijalen_zivot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_3_socijalen_zivot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_9_3_socijalen_zivot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_9_3_socijalen_zivot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_3_socijalen_zivot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_9_3_socijalen_zivot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_3_socijalen_zivot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_3_socijalen_zivot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_9_3_socijalen_zivot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_3_socijalen_zivot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_3_socijalen_zivot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_9_3_socijalen_zivot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_9_3_socijalen_zivot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_3_socijalen_zivot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_9_4_socijalen_zivot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_9_4_socijalen_zivot_opservacija"
                                            subject_details={this.state.subject_details}
                                            read_only={this.state.read_only}
                                            field={'2_6_9_4_socijalen_zivot_opservacija'}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_9_4_socijalen_zivot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_9_4_socijalen_zivot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_4_socijalen_zivot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_9_4_socijalen_zivot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_9_4_socijalen_zivot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_4_socijalen_zivot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_9_4_socijalen_zivot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_4_socijalen_zivot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_4_socijalen_zivot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_9_4_socijalen_zivot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_4_socijalen_zivot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_4_socijalen_zivot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_9_4_socijalen_zivot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_9_4_socijalen_zivot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_4_socijalen_zivot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_9_5_socijalen_zivot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_9_5_socijalen_zivot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_5_socijalen_zivot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_9_5_socijalen_zivot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_9_5_socijalen_zivot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_5_socijalen_zivot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_9_5_socijalen_zivot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_9_5_socijalen_zivot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_5_socijalen_zivot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_9_5_socijalen_zivot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_5_socijalen_zivot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_5_socijalen_zivot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_9_5_socijalen_zivot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_5_socijalen_zivot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_5_socijalen_zivot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_9_5_socijalen_zivot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_9_5_socijalen_zivot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_5_socijalen_zivot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c26">
                                    <td class="c1" colspan="1" rowspan="1" id="2_6_9_6_socijalen_zivot_opservacija">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_2_6_9_6_socijalen_zivot_opservacija"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_6_socijalen_zivot_opservacija'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c12" colspan="1" rowspan="1" id="2_6_9_6_socijalen_zivot_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_2_6_9_6_socijalen_zivot_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_6_socijalen_zivot_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c13" colspan="1" rowspan="1" id="2_6_9_6_socijalen_zivot_kvalifikator">
                                        <SubjectSelect
                                            multiple={true}
                                            reference={'remote.view_code_setup_icf_qualifier'}
                                            className="sbj_ms sbj_key_2_6_9_6_socijalen_zivot_kvalifikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_6_socijalen_zivot_kvalifikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>

                                    <td class="c16" colspan="1" rowspan="1" id="2_6_9_6_socijalen_zivot_indikator">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_6_socijalen_zivot_indikator"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_6_socijalen_zivot_indikator'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c17" colspan="1" rowspan="1" id="2_6_9_6_socijalen_zivot_olesnu_vac">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_indicator'}
                                            className="sbj_tag sbj_key_2_6_9_6_socijalen_zivot_olesnu_vac"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_6_socijalen_zivot_olesnu_vac'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c4" colspan="2" rowspan="1" id="2_6_9_6_socijalen_zivot_bariera">
                                        <SubjectTag
                                            reference={'remote.view_facilitator_and_barrier_qualifier'}
                                            className="sbj_tag sbj_key_2_6_9_6_socijalen_zivot_bariera"
                                            subject_details={this.state.subject_details}
                                            field={'2_6_9_6_socijalen_zivot_bariera'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_3-0" start="3">
                            <li
                                class="c20 c50 li-bullet-0"
                                style={{
                                    textAlign: 'center',
                                    marginLeft: '0',
                                    paddingLeft: '0 '
                                }}
                            >
                                <span class="c0">МИСЛЕЊЕ</span>
                            </li>
                        </ol>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20">
                            <span>
                                1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мислење за функционалните способности
                                и видот и степенот на попреченост (
                            </span>
                            <span>описно</span>
                            <span class="c0">) </span>
                        </p>
                        <a id="t.779b2f51cc7b5fca31e0e96ebf4fb886eb8ceb92"></a>
                        <a id="t.8"></a>
                        <table
                            class="c77"
                            id="3_1_mislenje_za_funcionlnite_sposobnosti_i_vidot_i_stepenot_na_poprecenost"
                        >
                            <SubjectTag
                                reference={'remote.view_code_setup_disability'}
                                className="sbj_tag sbj_key_3_1_mislenje_za_funcionlnite_sposobnosti_i_vidot_i_stepenot_na_poprecenost"
                                subject_details={this.state.subject_details}
                                field={'3_1_mislenje_za_funcionlnite_sposobnosti_i_vidot_i_stepenot_na_poprecenost'}
                                read_only={this.state.read_only}
                            />
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_4-0 start" start="2">
                            <li class="c20 c10 li-bullet-2">
                                <span class="c0">
                                    Цели на учество кои треба да бидат постигнати со процесот на поддршка
                                </span>
                            </li>
                        </ol>
                        <a id="t.af826ba9638561409df897e1e137bfed45633d51"></a>
                        <a id="t.9"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c19">
                                    <td class="c91 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Описно</span>
                                        </p>
                                    </td>
                                    <td class="c5" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">МКФ КОД</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c19">
                                    <td class="c91" colspan="1" rowspan="1">
                                        <ol class="c48 lst-kix_list_1-0 start" start="1">
                                            <li class="c2 c10 li-bullet-2">
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'super',
                                                        height: '100%',
                                                        marginLeft: '-40px',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    1.
                                                </span>
                                                <span id="3_2_1_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno">
                                                    <SubjectInput
                                                        multiline={true}
                                                        type="text"
                                                        className="sbj_mtb sbj_key_3_2_1_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno"
                                                        subject_details={this.state.subject_details}
                                                        field={
                                                            '3_2_1_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno'
                                                        }
                                                        read_only={this.state.read_only}
                                                    />
                                                </span>
                                            </li>
                                        </ol>
                                    </td>
                                    <td
                                        class="c71"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_2_1_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_3_2_1_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={
                                                '3_2_1_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode'
                                            }
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c19">
                                    <td class="c91" colspan="1" rowspan="1">
                                        <ol class="c48 lst-kix_list_5-0 start" start="1">
                                            <li class="c2 c10 li-bullet-1">
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'super',
                                                        height: '100%',
                                                        marginLeft: '-40px',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    2.
                                                </span>
                                                <span id="3_2_2_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno">
                                                    <SubjectInput
                                                        multiline={true}
                                                        type="text"
                                                        className="sbj_mtb sbj_key_3_2_2_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno"
                                                        subject_details={this.state.subject_details}
                                                        field={
                                                            '3_2_2_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno'
                                                        }
                                                        read_only={this.state.read_only}
                                                    />
                                                </span>
                                            </li>
                                        </ol>
                                    </td>
                                    <td
                                        class="c71"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_2_2_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_3_2_2_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={
                                                '3_2_2_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode'
                                            }
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c19">
                                    <td class="c91" colspan="1" rowspan="1">
                                        <ol class="c48 lst-kix_list_5-0" start="2">
                                            <li class="c2 c10 li-bullet-2">
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'super',
                                                        height: '100%',
                                                        marginLeft: '-40px',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    3.
                                                </span>
                                                <span id="3_2_3_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno">
                                                    <SubjectInput
                                                        multiline={true}
                                                        type="text"
                                                        className="sbj_mtb sbj_key_3_2_3_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno"
                                                        subject_details={this.state.subject_details}
                                                        field={
                                                            '3_2_3_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno'
                                                        }
                                                        read_only={this.state.read_only}
                                                    />
                                                </span>
                                            </li>
                                        </ol>
                                    </td>
                                    <td
                                        class="c71"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_2_3_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_3_2_3_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={
                                                '3_2_3_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode'
                                            }
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c19">
                                    <td class="c91" colspan="1" rowspan="1">
                                        <ol class="c48 lst-kix_list_5-0" start="3">
                                            <li class="c2 c10 li-bullet-2">
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'super',
                                                        height: '100%',
                                                        marginLeft: '-40px',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    4.
                                                </span>
                                                <span id="3_2_4_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno">
                                                    <SubjectInput
                                                        multiline={true}
                                                        type="text"
                                                        className="sbj_mtb sbj_key_3_2_4_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno"
                                                        subject_details={this.state.subject_details}
                                                        field={
                                                            '3_2_4_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno'
                                                        }
                                                        read_only={this.state.read_only}
                                                    />
                                                </span>
                                            </li>
                                        </ol>
                                    </td>
                                    <td
                                        class="c71"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_2_4_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_3_2_4_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={
                                                '3_2_4_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode'
                                            }
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c19">
                                    <td class="c91" colspan="1" rowspan="1">
                                        <ol class="c48 lst-kix_list_5-0" start="3">
                                            <li class="c2 c10 li-bullet-2">
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'super',
                                                        height: '100%',
                                                        marginLeft: '-40px',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    5.
                                                </span>
                                                <span id="3_2_5_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno">
                                                    <SubjectInput
                                                        multiline={true}
                                                        type="text"
                                                        className="sbj_mtb sbj_key_3_2_5_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno"
                                                        subject_details={this.state.subject_details}
                                                        field={
                                                            '3_2_5_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno'
                                                        }
                                                        read_only={this.state.read_only}
                                                    />
                                                </span>
                                            </li>
                                        </ol>
                                    </td>
                                    <td
                                        class="c71"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_2_5_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_3_2_5_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={
                                                '3_2_5_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode'
                                            }
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c19">
                                    <td class="c91" colspan="1" rowspan="1">
                                        <ol class="c48 lst-kix_list_5-0" start="3">
                                            <li class="c2 c10 li-bullet-2">
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'super',
                                                        height: '100%',
                                                        marginLeft: '-40px',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    6.
                                                </span>
                                                <span id="3_2_6_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno">
                                                    <SubjectInput
                                                        multiline={true}
                                                        type="text"
                                                        className="sbj_mtb sbj_key_3_2_6_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno"
                                                        subject_details={this.state.subject_details}
                                                        field={
                                                            '3_2_6_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_opisno'
                                                        }
                                                        read_only={this.state.read_only}
                                                    />
                                                </span>
                                            </li>
                                        </ol>
                                    </td>
                                    <td
                                        class="c71"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_2_6_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_participation_codes'}
                                            className="sbj_tag sbj_key_3_2_6_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={
                                                '3_2_6_celi_na_ucestvo_koi_treba_da_bidat_postignati_so_procesor_na_poddrska_mkfcode'
                                            }
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_4-0" start="3">
                            <li class="c20 c10 li-bullet-1">
                                <span class="c0">Семејни цели</span>
                            </li>
                        </ol>
                        <a id="t.0e1dcd81bf2329d9a63b73ce4c6b0b9808072cb0"></a>
                        <a id="t.10"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c19">
                                    <td class="c91 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Описно</span>
                                        </p>
                                    </td>
                                    <td class="c5" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">МКФ КОД</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c19">
                                    <td class="c91" colspan="1" rowspan="1" id="3_3_1_semejni_celi_opisno">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_3_1_semejni_celi_opisno"
                                            subject_details={this.state.subject_details}
                                            field={'3_3_1_semejni_celi_opisno'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c71" colspan="1" rowspan="1" id="3_3_1_semejni_celi_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_codes_facilitator_and_barrier'}
                                            className="sbj_tag sbj_key_3_3_1_semejni_celi_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'3_3_1_semejni_celi_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c19">
                                    <td class="c91" colspan="1" rowspan="1" id="3_3_2_semejni_celi_opisno">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_3_2_semejni_celi_opisno"
                                            subject_details={this.state.subject_details}
                                            field={'3_3_2_semejni_celi_opisno'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c71" colspan="1" rowspan="1" id="3_3_2_semejni_celi_mkfcode">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_codes_facilitator_and_barrier'}
                                            className="sbj_tag sbj_key_3_3_2_semejni_celi_mkfcode"
                                            subject_details={this.state.subject_details}
                                            field={'3_3_2_semejni_celi_mkfcode'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <ol class="c48 lst-kix_list_4-0" start="4">
                            <li class="c20 c10 li-bullet-2">
                                <span class="c0">Препорачани права и услуги</span>
                            </li>
                        </ol>
                        <a id="t.3e5e1625a2de4907652c3e8550e5d35353fba55e"></a>
                        <a id="t.11"></a>
                        <table class="c77">
                            <tbody>
                                <tr class="c36">
                                    <td class="c64 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Сектор</span>
                                        </p>
                                    </td>
                                    <td class="c37 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Што се препорачува</span>
                                        </p>
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                    </td>
                                    <td class="c97 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Колку често</span>
                                        </p>
                                    </td>
                                    <td class="c24 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Кој (конкретни контакти)</span>
                                        </p>
                                    </td>
                                    <td class="c8" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Каде (адреси)</span>
                                        </p>
                                    </td>
                                    <td class="c32 c47" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Потреба за координација и соработка (Слободен текст)</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c36">
                                    <td class="c64 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span>Здравствена </span>
                                            <span>заштита</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c37"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_4_zdravestvena_zastita_sto_se_preporacuva"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_health_care_measures'}
                                            className="sbj_tag sbj_key_3_4_zdravestvena_zastita_sto_se_preporacuva"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_zdravestvena_zastita_sto_se_preporacuva'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c97" colspan="1" rowspan="1" id="3_4_zdravestvena_zastita_kolku_cesto">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_key_3_4_zdravestvena_zastita_kolku_cesto"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_zdravestvena_zastita_kolku_cesto'}
                                            read_only={this.state.read_only}
                                        />
                                        {/* <SubjectTag
                      reference={"remote.view_code_setup_indicator"}
                      className="sbj_tag sbj_key_3_4_zdravestvena_zastita_kolku_cesto"
                      subject_details={this.state.subject_details}
                      field={"3_4_zdravestvena_zastita_kolku_cesto"}
                      read_only={this.state.read_only}
                    /> */}
                                    </td>
                                    <td class="c24" colspan="1" rowspan="1" id="3_4_zdravestvena_zastita_koj">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_institution_health'}
                                            className="sbj_tag 3_4_zdravestvena_zastita_koj"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_zdravestvena_zastita_koj'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c49" colspan="1" rowspan="1" id="3_4_zdravestvena_zastita_kade">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_4_zdravestvena_zastita_kade"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_zdravestvena_zastita_kade'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c47"
                                        colspan="1"
                                        rowspan="4"
                                        id="3_4_potreba_za_koordinacija_i_sorabotka"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_4_potreba_za_koordinacija_i_sorabotka"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_potreba_za_koordinacija_i_sorabotka'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c58">
                                    <td class="c64 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Образование</span>
                                        </p>
                                    </td>
                                    <td class="c37" colspan="1" rowspan="1" id="3_4_obrazovanie_sto_se_preporacuva">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_child_support_measures'}
                                            className="sbj_tag sbj_key_3_4_obrazovanie_sto_se_preporacuva"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_obrazovanie_sto_se_preporacuva'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c97" colspan="1" rowspan="1" id="3_4_obrazovanie_kolku_cesto">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="3_4_obrazovanie_kolku_cesto"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_obrazovanie_kolku_cesto'}
                                            read_only={this.state.read_only}
                                        />
                                        {/* <SubjectTag
                      reference={"remote.view_code_setup_indicator"}
                      className="sbj_tag sbj_key_3_4_obrazovanie_kolku_cesto"
                      subject_details={this.state.subject_details}
                      field={"3_4_obrazovanie_kolku_cesto"}
                      read_only={this.state.read_only}
                    /> */}
                                    </td>
                                    <td class="c24" colspan="1" rowspan="1" id="3_4_obrazovanie_koj">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_4_obrazovanie_koj"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_obrazovanie_koj'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c49" colspan="1" rowspan="1" id="3_4_obrazovanie_kade">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_4_obrazovanie_kade"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_obrazovanie_kade'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c36">
                                    <td class="c64 c32" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Детска и социјална заштита</span>
                                        </p>
                                    </td>
                                    <td
                                        class="c37"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_4_detska_i_socijalna_zastita_sto_se_preporacuva"
                                    >
                                        <SubjectTag
                                            reference={'remote.view_code_setup_social_protection_measures'}
                                            className="sbj_tag sbj_key_3_4_detska_i_socijalna_zastita_sto_se_preporacuva"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_detska_i_socijalna_zastita_sto_se_preporacuva'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td
                                        class="c97"
                                        colspan="1"
                                        rowspan="1"
                                        id="3_4_detska_i_socijalna_zastita_kolku_cesto"
                                    >
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="3_4_detska_i_socijalna_zastita_kolku_cesto"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_detska_i_socijalna_zastita_kolku_cesto'}
                                            read_only={this.state.read_only}
                                        />
                                        {/* <SubjectTag
                      reference={"remote.view_code_setup_indicator"}
                      className="sbj_tag sbj_key_3_4_detska_i_socijalna_zastita_kolku_cesto"
                      subject_details={this.state.subject_details}
                      field={"3_4_detska_i_socijalna_zastita_kolku_cesto"}
                      read_only={this.state.read_only}
                    /> */}
                                    </td>
                                    <td class="c24" colspan="1" rowspan="1" id="3_4_detska_i_socijalna_zastita_koj">
                                        <SubjectTag
                                            reference={'remote.view_code_setup_institution_social'}
                                            className="sbj_tag 3_4_detska_i_socijalna_zastita_koj"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_detska_i_socijalna_zastita_koj'}
                                            multiple={true}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c49" colspan="1" rowspan="1" id="3_4_detska_i_socijalna_zastita_kade">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_4_detska_i_socijalna_zastita_kade"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_detska_i_socijalna_zastita_kade'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                                <tr class="c36">
                                    <td class="c32 c64" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Други</span>
                                        </p>
                                    </td>
                                    <td class="c37" colspan="1" rowspan="1" id="3_4_drugi_sto_se_preporacuva">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_4_drugi_sto_se_preporacuva"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_drugi_sto_se_preporacuva'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c97" colspan="1" rowspan="1" id="3_4_drugi_kolku_cesto">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="3_4_drugi_kolku_cesto"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_drugi_kolku_cesto'}
                                            read_only={this.state.read_only}
                                        />
                                        {/* <SubjectTag
                      reference={"remote.view_code_setup_indicator"}
                      className="sbj_tag sbj_key_3_4_drugi_kolku_cesto"
                      subject_details={this.state.subject_details}
                      field={"3_4_drugi_kolku_cesto"}
                      read_only={this.state.read_only}
                    /> */}
                                    </td>
                                    <td class="c24" colspan="1" rowspan="1" id="3_4_drugi_koj">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_4_drugi_koj"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_drugi_koj'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c49" colspan="1" rowspan="1" id="3_4_drugi_kade">
                                        <SubjectInput
                                            multiline={true}
                                            type="text"
                                            className="sbj_mtb sbj_key_3_4_drugi_kade"
                                            subject_details={this.state.subject_details}
                                            field={'3_4_drugi_kade'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ol class="c48 lst-kix_list_4-0" start="5">
                            <li class="c20 c10 li-bullet-2">
                                <span class="c0">
                                    Размена на информации &nbsp;(со кого: семеен доктор, училиште, градинка):
                                </span>
                            </li>
                        </ol>
                        <a id="t.63be074eda0054f6ad6349497c32854494cc7da1"></a>
                        <a id="t.12"></a>
                        <table class="c77" id="3_5_razmena_na_informacii">
                            <SubjectInput
                                multiline={true}
                                type="text"
                                className="sbj_mtb sbj_key_3_5_razmena_na_informacii"
                                subject_details={this.state.subject_details}
                                field={'3_5_razmena_na_informacii'}
                                read_only={this.state.read_only}
                            />
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20">
                            <span class="c0">
                                ИМЕ И ПРЕЗИМЕ И ПОТПИС НА РОДИТЕЛ/СТАРАТЕЛ КОЈ УЧЕСТВУВАЛ ВО ПРОЦЕСОТ НА ПРОЦЕНКАТА:
                                ____________________________________, ________________
                            </span>
                        </p>
                        <a id="t.746c79bce043289c7fb247714cbecf4a182f8662"></a>
                        <a id="t.13"></a>
                        <table class="c111">
                            <tbody>
                                <tr class="c112">
                                    <td class="c81 c109" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Повторна проценка (кога) :</span>
                                        </p>
                                    </td>
                                    <td class="c37" colspan="1" rowspan="1" id="3_5_date_of_reassessment">
                                        <SubjectInput
                                            type="date"
                                            className="sbj_date sbj_key_3_5_date_of_reassessment"
                                            subject_details={this.state.subject_details}
                                            field={'3_5_date_of_reassessment'}
                                            read_only={this.state.read_only}
                                        />
                                    </td>
                                    <td class="c25 c81" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span>Стручни</span>
                                            <span>лица</span>
                                            <span class="c0">:</span>
                                        </p>
                                    </td>
                                    <td class="c41 c81" colspan="1" rowspan="1">
                                        <p class="c2">
                                            <span class="c0">Потпис на стручните лица</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr class="c56">
                                    <td class="c87" colspan="2" rowspan="1">
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                    </td>
                                    <td class="c25" colspan="1" rowspan="1">
                                        <span
                                            class="c48 lst-kix_list_6-0 start"
                                            start="1"
                                            id="social_worker.first_name"
                                        >
                                            {this.state.social_worker.first_name} {this.state.social_worker.last_name} -{' '}
                                            {global.UF.tr(this.state.social_worker.specialist_type)}
                                        </span>
                                        <span class="c0" id="3_5_social_worker_fullname">
                                            <SubjectInput
                                                type="text"
                                                className="sbj_stb sbj_key_3_5_social_worker_fullname"
                                                subject_details={this.state.subject_details}
                                                field={'3_5_social_worker_fullname'}
                                                read_only={this.state.read_only}
                                            />
                                        </span>
                                    </td>
                                    <td class="c41" colspan="1" rowspan="1" id="approved_social_worker">
                                        <SubjectApproveButton
                                            className="sbj_btn sbj_key_approved_social_worker"
                                            user={this.props.user}
                                            subject_id={this.state.id}
                                            field={'approved_social_worker'}
                                            updateSubject={this.fetchSpecialistAndDoctors}
                                            read_only={this.state.read_only}
                                            patient={this.state.patients}
                                            subject={this.state.subject}
                                        />
                                    </td>
                                </tr>
                                <tr class="c56">
                                    <td class="c87" colspan="2" rowspan="1">
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                    </td>
                                    <td class="c25" colspan="1" rowspan="1">
                                        <span class="c48 lst-kix_list_6-0" start="2" id="psychologist.first_name">
                                            {this.state.psychologist.first_name} {this.state.psychologist.last_name} -{' '}
                                            {global.UF.tr(this.state.psychologist.specialist_type)}
                                        </span>
                                        <span class="c0" id="3_5_psychologist_fullname">
                                            <SubjectInput
                                                type="text"
                                                className="sbj_stb sbj_key_3_5_psychologist_fullname"
                                                subject_details={this.state.subject_details}
                                                field={'3_5_psychologist_fullname'}
                                                read_only={this.state.read_only}
                                            />
                                        </span>
                                    </td>
                                    <td class="c41" colspan="1" rowspan="1" id="approved_psychologist">
                                        <SubjectApproveButton
                                            className="sbj_btn sbj_key_approved_psychologist"
                                            user={this.props.user}
                                            subject_id={this.state.id}
                                            field={'approved_psychologist'}
                                            updateSubject={this.fetchSpecialistAndDoctors}
                                            read_only={this.state.read_only}
                                            patient={this.state.patients}
                                            subject={this.state.subject}
                                        />
                                    </td>
                                </tr>
                                <tr class="c56">
                                    <td class="c87" colspan="2" rowspan="1">
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                    </td>
                                    <td class="c25" colspan="1" rowspan="1">
                                        <span
                                            class="c48 lst-kix_list_6-0"
                                            start="3"
                                            id="special_educator_and_rehabilitator.first_name"
                                        >
                                            {this.state.special_educator_and_rehabilitator.first_name}{' '}
                                            {this.state.special_educator_and_rehabilitator.last_name} -{' '}
                                            {global.UF.tr(
                                                this.state.special_educator_and_rehabilitator.specialist_type
                                            )}
                                        </span>
                                        <span class="c0" id="3_5_special_educator_and_rehabilitator_fullname">
                                            <SubjectInput
                                                type="text"
                                                className="sbj_stb sbj_key_3_5_special_educator_and_rehabilitator_fullname"
                                                subject_details={this.state.subject_details}
                                                field={'3_5_special_educator_and_rehabilitator_fullname'}
                                                read_only={this.state.read_only}
                                            />
                                        </span>
                                    </td>
                                    <td
                                        class="c41"
                                        colspan="1"
                                        rowspan="1"
                                        id="approved_special_educator_and_rehabilitator"
                                    >
                                        <SubjectApproveButton
                                            className="sbj_btn sbj_key_approved_special_educator_and_rehabilitator"
                                            user={this.props.user}
                                            subject_id={this.state.id}
                                            field={'approved_special_educator_and_rehabilitator'}
                                            updateSubject={this.fetchSpecialistAndDoctors}
                                            patient={this.state.patients}
                                            subject={this.state.subject}
                                        />
                                    </td>
                                </tr>
                                <tr class="c56">
                                    <td class="c87" colspan="2" rowspan="1">
                                        <p class="c2 c3">
                                            <span class="c0"></span>
                                        </p>
                                    </td>
                                    <td class="c25" colspan="1" rowspan="1">
                                        <span class="c0" id="doctor.first_name">
                                            {this.state.doctor.first_name} {this.state.doctor.last_name} -{' '}
                                            {this.state.doctor.doctor_type}
                                        </span>

                                        <span class="c0" id="3_5_doctor_fullname">
                                            <SubjectInput
                                                type="text"
                                                className="sbj_stb sbj_key_3_5_doctor_fullname"
                                                subject_details={this.state.subject_details}
                                                field={'3_5_doctor_fullname'}
                                                read_only={this.state.read_only}
                                            />
                                        </span>
                                    </td>
                                    <td class="c41" colspan="1" rowspan="1" id="approved_doctor">
                                        <SubjectApproveButton
                                            className="sbj_btn sbj_key_approved_doctor"
                                            user={this.props.user}
                                            subject_id={this.state.id}
                                            subject={this.state.subject}
                                            field={'approved_doctor'}
                                            updateSubject={this.fetchSpecialistAndDoctors}
                                            patient={this.state.patients}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20">
                            <span class="c34">ДАТУМ : _</span>
                            <span class="c34">___._____, ____________</span>
                        </p>
                        <p class="c20 c3" id="h.gjdgxs">
                            <span class="c0"></span>
                        </p>
                        <p class="c20">
                            <span class="c34">МЕСТО</span>
                            <span>:</span>
                            <span class="c34">&nbsp;</span>
                            <span id="3_6_mesto">
                                <SubjectInput
                                    type="text"
                                    className="sbj_stb sbj_key_3_6_mesto"
                                    subject_details={this.state.subject_details}
                                    field={'3_6_mesto'}
                                    read_only={this.state.read_only}
                                />
                            </span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <p class="c20 c3">
                            <span class="c0"></span>
                        </p>
                        <div>
                            <p class="c3 c98">
                                <span class="c0"></span>
                            </p>
                            <a id="t.1cd08da199d18584aa8fa93dcef66e1253caa512"></a>
                            <a id="t.14"></a>
                            <table class="c54">
                                <tbody>
                                    <tr class="c36">
                                        <td class="c106" colspan="1" rowspan="1">
                                            <p class="c3 c66">
                                                <span class="c34 c6 c84"></span>
                                            </p>
                                        </td>
                                        <td class="c72" colspan="1" rowspan="1">
                                            <p class="c2">
                                                <span class="c34 c115">
                                                    МЕЃУНАРОДНА КЛАСИФИКАЦИЈА НА ФУНКЦИОНИРАЊЕ, ПОПРЕЧЕНОСТ И ЗДРАВЈЕ
                                                    |МКФ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp; _________________________________
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="c2 c3">
                                <span class="c0"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </Grid>
        );
    }
}

export default withRouter(Subjects);
