import React from 'react';
import UF_Content from '../../../nix_components/components/UF_Content';
import withRouter from '../../../nix_components/components/withRouter';
import UF_DateTimePicker from '../../../nix_components/ui_components/controls/nx_ctrl_datetime';
import data_types from '../../../nix_components/data/data_types';
import Grid from '../../../nix_components/ui_components/layouts/Grid/Grid';
import moment from 'moment';
import NxIconButton from '../../../nix_components/ui_components/controls/nx_icon_button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Nx_Dialog from '../../../nix_components/ui_components/dialogs/nx_dialog';
import NxGridView from '../../../nix_components/components/NxGridView';

class Reports extends React.Component {
    constructor(props) {
        super(props);
        const date = new Date();
        const date_from = new Date();
        const date_to = date.setMonth(date.getMonth() + 1);
        this.state = {
            date_from: moment(date_from).format('YYYY-MM-DD'),
            date_to: moment(date_to).format('YYYY-MM-DD'),
            active_record: null
        };
        global.UF.dataProvider.datastructure['view_patients_reports'].filters = {
            custom_filter: `date_of_assessment between '${this.state.date_from}' and '${this.state.date_to}'`
        };
    }

    fetchData = () => {};

    buttons = {
        showWholeRecord: params => {
            return (
                <NxIconButton
                    tooltip={'Прегледај ивештај'}
                    color="default"
                    onClick={() => this.setState({ active_record: params.row })}
                >
                    <VisibilityIcon />
                </NxIconButton>
            );
        }
    };

    formConfig = {
        rows: []
    };

    gridProps = {
        fields: [
            'ordinal_number',
            'fullname',
            'diagnosis_tag',
            'thoughts_tag',
            'health_recommendation_ref',
            'learning_recommendation_ref',
            'child_recommendation_ref',
            'other_recommendation',
            'note'
        ]
    };

    viewConfig = {
        rows: [
            ['ordinal_number', 'fullname'],
            ['date_of_birth', 'address'],
            ['id_number', 'responsible_person'],
            ['date_of_assessment', 'contact_name'],
            ['date_of_reassessment', {}],
            ['note', 'diagnosis'],
            ['education', 'thoughts'],
            ['other_recommendation', 'health_recommendation'],
            ['learning_recommendation', 'child_recommendation']
        ]
    };

    viewButtons = [
        {
            label: 'Затвори',
            variant: 'text',
            onClick: () => this.setState({ active_record: null })
        }
    ];

    handleRowClick = row => {
        const dataItem = row.row;
        this.props.router.navigate(`/patients/${dataItem.patient_id}`);
    };

    handleTags = item => {
        const temp_arr_diagnosis = [];
        if (item.diagnosis_tag) {
            const regex = /\[\[.*?\]\]/g;
            const matches = item.diagnosis_tag.match(regex);
            if (matches?.length) {
                matches.forEach(match => {
                    const parsed_match = JSON.parse(match);
                    temp_arr_diagnosis.push(parsed_match[0][0].id);
                });
            }
            item.diagnosis_tag = temp_arr_diagnosis;
        }
        const temp_arr_thoughts = [];
        if (item.thoughts_tag) {
            const regex = /\[\[.*?\]\]/g;
            const matches = item.thoughts_tag.match(regex);
            if (matches?.length) {
                matches.forEach(match => {
                    const parsed_match = JSON.parse(match);
                    temp_arr_thoughts.push(parsed_match[0][0].id);
                });
            }
            item.thoughts_tag = temp_arr_thoughts;
        }

        if (item.health_recommendation_ref && item.health_recommendation_ref.length) {
            item.health_recommendation_ref = JSON.parse(item.health_recommendation_ref);
        }

        if (item.learning_recommendation_ref && item.learning_recommendation_ref.length) {
            item.learning_recommendation_ref = JSON.parse(item.learning_recommendation_ref);
        }

        if (item.child_recommendation_ref && item.child_recommendation_ref.length) {
            item.child_recommendation_ref = JSON.parse(item.child_recommendation_ref);
        }
    };

    onDataItemsLoad = (data, callback) => {
        if (!data.length) {
            if (callback) {
                callback();
            }
        }

        data.forEach(item => {
            this.handleTags(item);
        });

        if (callback) {
            callback();
        }
    };

    renderDatePicker = () => {
        return (
            <Grid
                style={{
                    gap: '20px',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    maxWidth: '400px',
                    marginTop: '5px'
                }}
            >
                <UF_DateTimePicker
                    fieldConfig={{ label: 'Од', datatype: data_types.date }}
                    onChange={this.handleFromChange}
                    value={this.state.date_from}
                />
                <UF_DateTimePicker
                    fieldConfig={{ label: 'До', datatype: data_types.date }}
                    onChange={this.handleToChange}
                    value={this.state.date_to}
                />
            </Grid>
        );
    };

    handleFromChange = date_from => {
        setTimeout(() => {
            this.setState({ date_from }, () => {
                global.UF.dataProvider.datastructure['view_patients_reports'].filters = {
                    custom_filter: `date_of_assessment between '${this.state.date_from}' and '${this.state.date_to}'`
                };
                this.fetchData();
            });
        }, 500);
    };

    handleToChange = date_to => {
        setTimeout(() => {
            this.setState({ date_to }, () => {
                global.UF.dataProvider.datastructure['view_patients_reports'].filters = {
                    custom_filter: `date_of_assessment between '${this.state.date_from}' and '${this.state.date_to}'`
                };
                this.fetchData();
            });
        }, 500);
    };

    getFetchData = fetchData => {
        this.fetchData = fetchData;
    };

    renderWholeRecord = () => {
        return (
            <Nx_Dialog
                open={true}
                buttons={this.viewButtons}
                handleClose={() => this.setState({ active_record: null })}
            >
                <NxGridView
                    dataItem={this.state.active_record}
                    table="view_patients_reports"
                    title={'Извештаи'}
                    viewConfig={this.viewConfig}
                    buttons={[]}
                />
            </Nx_Dialog>
        );
    };

    render() {
        return (
            <>
                {this.state.active_record && this.renderWholeRecord()}
                <UF_Content
                    table="view_patients_reports"
                    title="Извештаи"
                    gridProps={this.gridProps}
                    buttons={this.buttons}
                    delete={false}
                    edit={false}
                    export={true}
                    rowAutoHeight={true}
                    fetchData={this.getFetchData}
                    onDataItemsLoad={this.onDataItemsLoad}
                    rowHeightAuto={true}
                    add={false}
                    renderCustomFilters={this.renderDatePicker()}
                    {...this.props}
                />
            </>
        );
    }
}

export default withRouter(Reports);
